/* eslint-disable indent */
// @ts-nocheck
import { FussyLevel, LifeStages } from "@/types/index";

import { TAG_VALUES } from "../consts";

// Used to work out if the dog is fussy, and if the dog is a puppy - Add anymore conditionals to this
export const getTagConditions = (lifeStage: LifeStages, fussy: FussyLevel) => {
	const isPuppy = lifeStage?.toLowerCase().includes("puppy");
	const isFussy = ["a little picky", "a real fusspot"].includes(
		fussy.toLowerCase()
	);
	return {
		popularWithPuppies: !!isPuppy,
		popular: !isPuppy,
		lovedByFussyDogs: isFussy,
		// add more conditions here as we need them
	};
};

export const getTagIndicesToDisplay = (
	tags,
	tagConditions,
	fussyIndexToExclude,
	popularIndexToExclude,
	popularPuppiesIndexToExclude,
	hasLooped = false
): Record<string, number[]> => {
	const firstIndexWithFussy = tagConditions.lovedByFussyDogs
		? tags.findIndex(
				({ tags: tagList }, index) =>
					fussyIndexToExclude !== index && tagList.includes(TAG_VALUES.fussy)
			)
		: -1;
	const firstIndexWithPopularPuppies = tagConditions.popularWithPuppies
		? tags.findIndex(
				({ tags: tagList }, index) =>
					index !== popularPuppiesIndexToExclude &&
					index !== firstIndexWithFussy &&
					tagList.includes(TAG_VALUES.popularWithPuppies)
			)
		: -1;
	const firstIndexWithPopular = tagConditions.popular
		? tags.findIndex(
				({ tags: tagList }, index) =>
					index !== popularIndexToExclude &&
					index !== firstIndexWithFussy &&
					tagList.includes(TAG_VALUES.popular)
			)
		: -1;

	const hasPopularTag =
		firstIndexWithPopular !== -1 || firstIndexWithPopularPuppies !== -1;
	const hasFussyTag = firstIndexWithFussy !== -1;

	const popularTagIndex =
		firstIndexWithPopular === -1
			? firstIndexWithPopularPuppies
			: firstIndexWithPopular;

	const needsToLoopAgain = !hasLooped && (!hasPopularTag || !hasFussyTag);

	if (needsToLoopAgain) {
		return getTagIndicesToDisplay(
			tags,
			tagConditions,
			firstIndexWithFussy,
			firstIndexWithPopular,
			firstIndexWithPopularPuppies,
			true
		);
	}

	return {
		fussy:
			fussyIndexToExclude || fussyIndexToExclude === 0
				? [fussyIndexToExclude, firstIndexWithFussy]
				: [firstIndexWithFussy],
		popular:
			popularIndexToExclude || popularIndexToExclude === 0
				? [popularIndexToExclude, firstIndexWithPopular]
				: [firstIndexWithPopular],
		popularWithPuppies:
			popularPuppiesIndexToExclude || popularPuppiesIndexToExclude === 0
				? [popularPuppiesIndexToExclude, firstIndexWithPopularPuppies]
				: [firstIndexWithPopularPuppies],
	};
};

// Work out which indices to display a tag on. Based on the getTagConditions and whether the indices already has a tag on it.
// Add more indice checks here
// At the time of writing, there is no limit check to keep tags under 2, adding more conditionals will require this.
export const prioritiseTagsToDisplay = (tags, tagConditions) => {
	const { fussy, popular, popularWithPuppies } = getTagIndicesToDisplay(
		tags,
		tagConditions
	);
	return tags.map((tag, index) =>
		fussy.includes(index)
			? { displayTag: "fussy", ...tag }
			: popular.includes(index)
				? { displayTag: "popular", ...tag }
				: popularWithPuppies.includes(index)
					? { displayTag: "popularWithPuppies", ...tag }
					: tag
	);
};
