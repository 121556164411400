export const successStoriesData = [
	{
		name: "Stacey",
		title: "New lease of life",
		content:
			"Rosie has been on Pure Food for 6 weeks now & OMG what a difference! You have given our 12 year old American Bulldog a whole new lease of life, her energy levels have raised & you can tell she is feeling better in herself. Her coat is so shiny her fur is really soft, looks like puppy fur again.",
		tags: ["sensitive skin", "senior"],
	},
	{
		name: "Laura",
		title: "Nothing but positive comments",
		content:
			"They have now been on the food for two months and I have nothing but positive comments... Such a great idea to add water afterwards to reduce packaging and transport weight, best of both worlds for us as it is convenient plus the dogs get enough moisture, as one of ours is a very thirsty pup!",
		tags: ["general", "customer service"],
	},
	{
		name: "Dottie",
		title: "Highly recommended",
		content:
			"My dog is in love with pure food I've never seen him lick the bowl clean everytime like he dose with pure dog food. His fur-skin and health have improved so much in a short space of time with eating pure food. I've highly recommended to other dog owners -that's how impressed I am.",
		tags: ["sensitive skin", "fussy"],
	},
	{
		name: "Kelly",
		title: "Back to his cheeky self",
		content:
			"This food has been fantastic for my Sprocker Ruger. He was diagnosed with pancreatitis. He has been on Pure Pet Food for a while and his stools have firmed up and no longer contain blood. He is now back to his cheeky self which I am very thankful for as it was a horrible time.",
		tags: ["pancreatitis", "general"],
	},
	{
		name: "Robyn",
		title: "Their stomach has gotten so much better",
		content:
			"My dog has always had a sensitive stomach but since switching to Pure it has gotten so much better! I started a subscription initially due to the multiple good reviews for dogs with similar issues. Overall recommend Pure and will be transitioning my second (less fussy) dog once his current food runs low.",
		tags: ["general", "sensitive stomach"],
	},
	{
		name: "Jilly",
		title: "Much much better quality",
		content:
			"I did some research and started my boxer on Pure, which he has been on for about 2 months now which doesn’t cost me any more than the wet tray food but is much much better quality. His stomach has improved lots and his coat is nicer than it has ever been, it’s soft, smooth and shiny! I would highly recommend.",
		tags: ["sensitive stomach", "sensitive skin", "general"],
	},
	{
		name: "Dani",
		title: "Thank you for changing our lives",
		content:
			"We have a fussy almost 2-yr old GSD. Researched a ton about the right food for him, found Pure and thought let's give it a try! He bloody loves it - breakfast & dinner are now his favourite part of the day. Thank you for changing our lives, we have struggled so much - his coat and weight is already better!",
		tags: ["sensitive skin", "fussy", "general", "underweight"],
	},
	{
		name: "Sophie",
		title: "The nutrition is excellent",
		content:
			"Since swapping food my dogs coat has become less greasy. The nutrition is excellent and I like that there’s variety in flavours to the food without causing stomach issues. Her stool is brilliant. Cannot fault the food. The pricing is slightly more expensive than some store providers but for the best nutrition it is certainly worth it.",
		tags: ["general", "sensitive skin", "sensitive stomach", "anal glands"],
	},
	{
		name: "Jean",
		title: "So glad we gave it a go",
		content:
			"I am so pleased with this food. Not only does my dog love to eat it, her stomach tolerates it really well. She gets bouts of colitis and this food has improved it dramatically. So glad we gave it a go.",
		tags: ["sensitive stomach", "colitis"],
	},
	{
		name: "Katherine",
		title: "Instantly noticed the benefits",
		content:
			"I have been using pure for a week now and have instantly noticed the benefits with my four month old kelpie. His toilet is perfect… cleaning up after him is pleasurable now compared to before when I was feeding him kibble and wet. His mood has improved and his coat is so soft. Thank you!!",
		tags: ["general", "anal glands", "sensitive skin"],
	},
	{
		name: "Carrie",
		title: "She most importantly loves her food",
		content:
			"Our super fussy dog was diagnosed with Pancreatitis a year ago. We have tried 2 different vet recommended foods. She had to be convinced to eat them. With Pure from day 1 she was hooked on her Salmon, sweet potato and coconut. Her coat also looks shiny, her weight has been maintained (an issue with Pancreatitis in dogs) and she most importantly loves her food.",
		tags: ["pancreatitis", "fussy", "general"],
	},
	{
		name: "Jan",
		title: "Always a clean bowl…immediately!",
		content:
			"He loves it. He has the salmon flavour and chicken flavour. His coat is shiny, he’s had no digestion issues and he always looks forward to his mealtimes. Always a clean bowl…immediately! Service from Pure Pet food has been excellent. Can highly recommend.",
		tags: ["fussy", "sensitive stomach", "general"],
	},
	{
		name: "Julia",
		title: "We always get compliments",
		content:
			"This is honestly the best food service I've ever had. The people are so friendly and make an effort to keep up to date on my growing pup. My pup loves the food and she has so much energy and is generally a healthy pup inside and out. We always get compliments so her nutrition definitely shines through.",
		tags: ["general", "service"],
	},
	{
		name: "Kelly",
		title: "He is now back to his cheeky self",
		content:
			"This food has been fantastic for my Sprocker Ruger. He was diagnosed with pancreatitis in October 2022. I wanted something that would settle his tummy and was healthy too. He has been on Pure for a while and his stools have firmed up and no longer contain blood. He is now back to his cheeky self.",
		tags: ["general", "pancreatitis"],
	},
	{
		name: "Daniel",
		title: "Overall I'm really impressed",
		content:
			"Having a dog with a lot of food allergies it can be hard to find a food that works. Most options are frozen raw or cooked food but it can complicated when freezer is space is limited. This pet food proved easy to store and prepare and my dog seems to love it. Overall I'm really impressed.",
		tags: ["allergies", "general"],
	},
	{
		name: "Jean",
		title: "I am so pleased with this food",
		content:
			"I am so pleased with this food. Not only does my dog love to eat it, her stomach tolerates it really well. She gets bouts of colitis and this food has improved it dramatically. So glad we gave it a go. Thank you.",
		tags: ["sensitive stomach"],
	},
	{
		name: "Helen",
		title: "Full with lots of vegetables and goodness",
		content:
			"Pure has been fantastic. It is full with lots of vegetables and goodness. Our Sproodle Django devours it lustily. On our side his coat is so soft and healthy. His poos are really good also. Thoroughly recommend.",
		tags: ["general", "anal glands", "sensitive skin"],
	},
	{
		name: "Kenneth",
		title: "It's amazing",
		content:
			"Moved my dog over to this food and can say it's amazing. Before faith had a flakey coat and since moving over to pure pet food this is now gone. Easy to make and easy to store",
		tags: ["sensitive skin", "general"],
	},
	{
		name: "Heather",
		title: "No more upset tummies",
		content:
			"Fantastic company. No more upset tummies for my Frenchie or trips to the vet as a result. She loves this food. Customer service is prompt and helpful. Thank you so much.",
		tags: ["sensitive stomach", "general"],
	},
	{
		name: "Brian",
		title: "My pups coat has improved",
		content:
			"This Pure pet food is easy to store and prepare quickly for hungry pups! My pups coat has improved in health and shine. There was an added bonus of her ears no longer being red and waxy. There is lots of flavours to choose from and the plan is easy to change to suit yourself.",
		tags: ["semsitive skin", "general"],
	},
	{
		name: "Natalie",
		title: "Best thing ever",
		content:
			"Our wee dog was so ill with chronic stomach issues our vet was suggesting dangerous surgery and life long medication which absolutely terrified us. We decided to try Pure. We saw an improvement in our boy almost instantly and it has cured all issues! We haven’t been to the vet in 9 weeks which is huge as we were going several times a week! Best thing ever.",
		tags: ["sensitive stomach", "general"],
	},
	{
		name: "Maria",
		title: "She's much happier",
		content:
			"My dog has a really sensitive stomach so after so many problems with supermarket food, gave Pure a try. It's been a few months now & I'm happy to see the gurgling & unsettled belly problems have vastly improved. She's much happier, has more energy & really enjoys her meals. Can't recommend it highly enough.",
		tags: ["sensitive stomach", "general"],
	},

	{
		name: "Jessica",
		title: "We are really happy with Pure",
		content:
			"She's always been very fussy and we found that she was very sensitive to lots of other brands. Since trying Pure she is so excited for breakfast and dinner and she's looking really healthy. Family and friends have commented on how well she looks now she's put some weight on. We are really happy with Pure and would recommend it to anyone!",
		tags: ["general", "fussy"],
	},
	{
		name: "Stu",
		title: "Loads of great recipes",
		content:
			"We love Pure Pet Food. We switched our lab puppy. Her coat is beautiful, she gets massively excited by her meals and her number twos are consistently perfect (who knew a side effect of dog ownership was poo watching?!). Mixing and serving couldn't be easier. Loads of great recipes too.",
		tags: ["general", "sensitive skin"],
	},
	{
		name: "Sarah",
		title: "Although he is super fussy, he loved it!",
		content:
			"My westie Stanley was previously on raw, I thought I would try Pure. Although he is super fussy, he loved it! He rarely leaves food in the bowl now, his stools are more consistent and he really seems to look forward to mealtimes! I’ve found the food so much more convenient. No storage issues (I have my freezer back!) and no remembering to defrost or worry about bacteria.",
		tags: ["general", "fussy"],
	},
	{
		name: "Holly",
		title: "The food is top quality",
		content:
			"I cannot fault Pure in anyway, shape or form, the food is top quality with top quality ingredients, perfect food for every dog, and so easy to prepare, you can't go wrong with not knowing how much to give either as you get your measuring scoops and they tell you how much to feed.",
		tags: ["general"],
	},
	{
		name: "Harriet",
		title: "They really care about your dog",
		content:
			"Pure Pet are a dog food brand like no other - they really care about your dog and finding recipes that work for your individual pet, tailoring it around them. You can speak to a trained nutritionalist for advice and their customer service is excellent. My dog absolutely loves their food and is far less itchy and much happier!",
		tags: ["general", "sensitive skin"],
	},
	{
		name: "Holly",
		title: "Overall an outstanding product",
		content:
			"Fantastic food, my dog adores it and must be left in peace when eating it! Lovely packaging and is recyclable. Great customer service and communications. Overall an outstanding product and business. Highly recommend!",
		tags: ["underweight", "fussy", "general"],
	},
	{
		name: "Tori",
		title: "I'm getting my beautiful girl back",
		content:
			"My girl has had skin issues & constant scratching. We decided to give Pure a go. So pleased we did, her skin on her legs is less flakey & seem to be clearing up & far less scratching. So happy, I'm getting my beautiful girl back.",
		tags: ["sensitive skin", "general"],
	},
	{
		name: "James",
		title: "The colitis has settled down",
		content:
			"Excellent service, excellent food. My dogs have never been this enthusiastic at meal times. My youngest westie has colitis and this has been a constant battle for 2 years. Since transitioning to pure, the colitis has settled down and fingers crossed has improved immeasurably. No midnight dash outside, no vomiting, no nasty poos for over 3 weeks now. Long may it continue.",
		tags: ["colitis", "general"],
	},
	{
		name: "Carolina",
		title: "My dog and I are in love with Pure food",
		content:
			"My dog and I are in love with Pure food. It's healthy, my dog's fur is more beautiful, it's easy to get warm water when travelling, I don't need a fridge after opening a can (what I was doing for a few months), and it's delivered to my house. Thank you so much for being great Pure food!",
		tags: ["general", "sensitive skin"],
	},
	{
		name: "Susan",
		title: "Our fussy pooch absolutely loves the food",
		content:
			"Our whole experience of the company, its products and customer service has been fantastic. Our fussy pooch absolutely loves the food and has gone from refusing food to practically eating it before I can get it in the bowl. Quick replies to any queries raised and helpful suggestions from the customer service team are another excellent part of this company.",
		tags: ["service", "fussy", "general"],
	},
	{
		name: "Kerry",
		title: "Noticed a vast improvement",
		content:
			"Maisie has been on Pure Pet Food for a few months. Noticed a vast improvement in her sensitive stomach, vomiting reduced significantly. Correspondence from the company is fantastic, can change my delivery date very easily & also Maisie's meal plan. Would definitely recommend.",
		tags: ["sensitive stomach", "general"],
	},
	{
		name: "Lisa",
		title: "Fabulous pet food",
		content:
			"Great communication, fabulous pet food, and easy hassle free subscription that you’re in control of all the time. Ordered because my dog had an upset stomach & friend recommended, tried all the high st brands, don’t have freezer space for Raw so thought I’d give the free trial a go. Wow, 12 hours and our dog was back to normal - fabulous!",
		tags: ["general", "service"],
	},
];
