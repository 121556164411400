import { gql } from "@apollo/client";

export const CREATE_TICKET_QUERY = gql`
	mutation CreateTicket($data: TicketInput!) {
		CreateTicket(input: $data) {
			id
			requester_id
			submitter_id
			organization_id
			problem_id
			assignee_id
			group_id
			subject
			raw_subject
			description
			priority
			status
			recipient
			external_id
			follower_ids
			tags
			sharing_agreement_ids
			collaborator_ids
			has_incidents
			url
			type
			due_at
			created_at
			updated_at
		}
	}
`;
