import { gql } from "@apollo/client";

export const SEND_FEEDBACK_MUTATION = gql`
	mutation CreateFeedback(
		$customer_id: Int
		$subscription_id: Int
		$input: FeedbackInput!
	) {
		CreateFeedback(
			customer_id: $customer_id
			subscription_id: $subscription_id
			input: $input
		) {
			id
			attached_id
			attached_type
			feedback_name
			feedback_description
			created_at
			updated_at
		}
	}
`;

export const UPDATE_RETENTION_REASON = gql`
	mutation UpdateRetention(
		$id: Int
		$subscription_id: Int
		$input: RetentionInput!
	) {
		UpdateRetention(id: $id, subscription_id: $subscription_id, input: $input) {
			id
			subscription_id
			cancellation_reason
			retained_by {
				id
				name
			}
			retention_reasons
			notes
			retained_via
			created_at
			updated_at
		}
	}
`;

export const ACCESS_MUTATION = gql`
	mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
		customerAccessTokenCreate(input: $input) {
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const RECOVER_MUTATION = gql`
	mutation SendPasswordReset($email: String!) {
		SendPasswordReset(email: $email)
	}
`;

export const SET_PASSWORD_MUTATION = gql`
	mutation SetPassword($setPassword: SetPasswordInput) {
		SetPassword(setPassword: $setPassword) {
			email
		}
	}
`;

export const RESET_PASSWORD_MUTATION = gql`
	mutation ResetPassword($resetPassword: ResetPasswordInput) {
		ResetPassword(resetPassword: $resetPassword) {
			email
		}
	}
`;

export const GET_CUSTOMER_QUERY = gql`
	query ($accessToken: String!) {
		customer(customerAccessToken: $accessToken) {
			firstName
			lastName
			phone
			email
			id
		}
	}
`;

export const CUSTOMER_QUERY = gql`
	query ($id: Int) {
		customer(id: $id) {
			shopify_id
			state
			email
			first_name
			last_name
			stripe_customer_id
			phone
			default_shipping_method
			available_shipping_methods
			referral {
				credit
			}
			pets {
				id
				name
				created_at
				updated_at
				gender
				allergy_count
				allergies
				issues_count
				issues
				fussy
				size
				breed
				age
				weight
				species
				activity_level
				treats_fed
				life_stage
				life_stage_enabled
				calculated_food_scoops
				calculated_water_scoops
				recipe_category
				working_dog
				suitable_recipes {
					id
					name
					handle
					product_id
					protein
					category
					product {
						title
						image
						tags
						variants {
							id
							title
							sku
							weight
							price
						}
					}
				}
				suitable_treats: treats {
					id
					name
					handle
					product_id
				}
				treats {
					id
					name
					handle
					product_id
					suitable
					product {
						title
						image
						tags
						variants {
							id
							title
							sku
							weight
							price
						}
					}
				}
				subscription {
					id
					new_address_id
					address_id
					price
					total
					delivery
					sub_length
					base_price
					base_size
					active
					use_per_subscription
					set_limit_amount
					discount_code_usage
					scoops_food
					scoops_water
					discount
					discount_code
					order_2_and_3_discount_test
					order_4_to_6_discount_test
					extra_credit
					cancellation_date
					cancellation_reason
					reactive_date
					primary_items {
						id
						itemable_id
						itemable_type
						title
						variant_title
						product_id
						variant_id
						image
						type
						uses
						quantity
						price
						default
						recipe {
							handle
							protein
							category
							discontinued
							product {
								title
								image
								tags
								variants {
									id
									title
									sku
									weight
									price
								}
							}
						}
						created_at
						updated_at
					}
					secondary_items {
						id
						itemable_id
						itemable_type
						title
						product_id
						variant_id
						image
						type
						uses
						quantity
						price
						default
						recipe {
							handle
							protein
							category
							discontinued
							product {
								title
								image
								tags
								variants {
									id
									title
									sku
									weight
									price
								}
							}
						}
						created_at
						updated_at
					}
					deliveries {
						id
						delivery_date
						status
						stripe_error
						total
						delivery
						new_address_id
						address_id
						discount
						is_locked
						processing_date
						scoop
						discount
						manual
						billing_date
						price
						discount_code
						primary_items {
							id
							itemable_id
							itemable_type
							title
							variant_title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							recipe {
								handle
								protein
								category
								discontinued
								subtitle
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
							created_at
							updated_at
						}
						secondary_items {
							id
							itemable_id
							itemable_type
							title
							product_id
							variant_id
							image
							type
							uses
							quantity
							price
							default
							created_at
							updated_at
							recipe {
								handle
								protein
								category
								discontinued
								subtitle
								product {
									title
									image
									tags
									variants {
										id
										title
										sku
										weight
										price
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const CUSTOMER_REFERRAL_SCHEMES = gql`
	query ($id: Int, $token: String) {
		customer(id: $id, token: $token) {
			referral {
				credit
				customers_count
				schemes {
					referralScheme {
						id
						token
						name
						pets
						end_date
					}
					duration
					slug
					amount
					ambassador_scheme
					discountCode {
						active
						code
						description
						amount
						type
						set_limit_amount
						type_limit
						future_discounts {
							code
							description
							amount
							type
							set_limit_amount
							type_limit
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_PET_MUTATION = gql`
	mutation UpdatePet($data: PetInput!, $shopify_id: String!, $item_id: Int!) {
		UpdatePet(shopify_id: $shopify_id, item_id: $item_id, input: $data) {
			id
			name
			gender
			allergy_count
			allergies
			issues_count
			issues
			fussy
			size
			species
			breed
			age
			weight
			neutered
			activity_level
			recipe_category
			calculated_food_scoops
			calculated_water_scoops
			treats_fed
			digestion
			behavioural
			stools
			improve_health
			mobility
			oral
			coat
			working_dog
			suitable_recipes {
				id
				name
				handle
				category
				product_id
				product {
					title
					image
					tags
					variants {
						id
						title
						sku
						weight
						price
						price_tests {
							name
							price
						}
					}
				}
			}
			treats {
				id
				name
				handle
				product_id
				suitable
				product {
					title
					image
					tags
					variants {
						id
						title
						sku
						weight
						price
					}
				}
			}
			subscription {
				id
				scoops_food
				scoops_water
				price
				sub_length
			}
		}
	}
`;

export const GET_ORDER_TRACKING_INFORMATION = gql`
	query orderStatus($delivery_id: Int) {
		orderStatus(delivery_id: $delivery_id) {
			status
			status_changed_at
			created_at
			packed_at
			ship_date
			courier
			latest_tracking_code
			latest_event_code
			late
			customer_tracking_number
		}
	}
`;

export const PROCESS_DELIVERY_MUTATION = gql`
	mutation processDelivery(
		$shopify_id: String
		$customer_id: Int
		$item_id: Int!
		$no_error: Boolean
	) {
		ProcessDelivery(
			shopify_id: $shopify_id
			customer_id: $customer_id
			item_id: $item_id
			no_error: $no_error
		) {
			id
			is_locked
			delivery_date
			error
			stripe_error
		}
	}
`;

export const UPDATE_SUBSCRIPTION_MUTATION = gql`
	mutation UpdateSubscription(
		$data: SubscriptionInput!
		$shopify_id: String!
		$item_id: Int!
	) {
		UpdateSubscription(
			shopify_id: $shopify_id
			item_id: $item_id
			input: $data
		) {
			id
			sub_length
			created_at
			active
			address_id
			new_address_id
			error
			next_delivery_date
			price
			base_size
		}
	}
`;

export const UPDATE_ITEMS_MUTATION = gql`
	mutation UpdateItems(
		$delivery_id: Int
		$subscription_id: Int
		$shopify_id: String
		$data: [ItemInput!]
	) {
		UpdateItems(
			delivery_id: $delivery_id
			subscription_id: $subscription_id
			shopify_id: $shopify_id
			input: $data
		) {
			id
			itemable_id
			itemable_type
			title
			variant_id
			type
			uses
			quantity
			price
			default
			created_at
			updated_at
		}
	}
`;

export const UPDATE_DELIVERY_MUTATION = gql`
	mutation UpdateDelivery(
		$data: DeliveryInput!
		$shopify_id: String!
		$item_id: Int!
		$clear_error: Boolean
	) {
		UpdateDelivery(
			shopify_id: $shopify_id
			item_id: $item_id
			input: $data
			clear_error: $clear_error
		) {
			delivery_date
			status
			id
			scoop
			recycling_label
			error
		}
	}
`;

export const STRIPE_QUERY = gql`
	query StripeIntent($customer: String) {
		stripeIntent(customer: $customer) {
			stripe_id
		}
	}
`;

export const STRIPE_MUTATION = gql`
	mutation UpdateCustomerCard($data: CustomerCardInput!) {
		UpdateCustomerCard(input: $data) {
			response
		}
	}
`;

export const DELETE_CUSTOMER_CARD = gql`
	mutation DeleteCustomerCard($data: CustomerCardInput!) {
		DeleteCustomerCard(input: $data) {
			response
		}
	}
`;

export const DELETE_ADDRESS_MUTATION = gql`
	mutation DeleteAddress($customer_id: Int, $item_id: Int) {
		DeleteAddress(customer_id: $customer_id, item_id: $item_id) {
			id
			customer_id
			line_1
			line_2
			city
			province
			postcode
			country
			phone
			created_at
			updated_at
		}
	}
`;

export const UPDATE_ADDRESS_MUTATION = gql`
	mutation UpdateAddress(
		$shopify_id: String
		$customer_id: Int
		$item_id: Int
		$data: AddressInput!
	) {
		UpdateAddress(
			shopify_id: $shopify_id
			customer_id: $customer_id
			item_id: $item_id
			input: $data
		) {
			id
			customer_id
			line_1
			line_2
			city
			province
			postcode
			country
			phone
			created_at
			updated_at
		}
	}
`;

export const FETCH_UPCOMING_FEATURES = gql`
	query MyQuery {
		upcomingFeaturesList {
			show
			features {
				thumbnail {
					responsiveImage(
						imgixParams: { fit: crop, w: 600, h: 300, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
				description
				dueDate
				id
				modalPromptText
				title
				suggestionCard
				modalIcon {
					responsiveImage(
						imgixParams: { fit: clip, w: 64, h: 64, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
				modalTitle
				modalContent
			}
		}
	}
`;

export const GET_CANCELLATION_FLOW_REASON = gql`
	query GetCancellationFlowReason(
		$customer_id: Int
		$leave_reason: String
		$flows_available: [String]
	) {
		cancellationFlowReason(
			customer_id: $customer_id
			leave_reason: $leave_reason
			flows_available: $flows_available
		) {
			id
			customer_id
			flows_available
			flow_chosen
		}
	}
`;
