import axios from "axios";

import { AddressDataReponse } from "../types";

const API_KEY = "IaKHy0gkJkuTmHfngQSzVA35693";

const URL = "https://api.getAddress.io/find/";

export const getAddress = async (
	postcode: string,
	houseNumber?: number
): Promise<AddressDataReponse> => {
	return await axios
		.get(
			`${URL}${postcode}${
				typeof houseNumber !== "undefined" ? `/${houseNumber}` : ""
			}?api-key=${API_KEY}&expand=true `
		)
		.then((data) => data.data)
		.catch((error) => console.log(error));
};
