import { gql } from "@apollo/client";

export const getHelpSearchData = gql`
	query allHelpArticles($limit: IntType, $skip: IntType) {
		allHelpArticles(first: $limit, skip: $skip) {
			id
			title
			slug
			keyword {
				keyword
			}
			metadata {
				title
				description
				date
			}
		}
		_allHelpArticlesMeta {
			count
		}
	}
`;

export const getHelpTitlesByCat = gql`
	query ($id: ItemId, $limit: IntType, $skip: IntType) {
		allHelpArticles(
			filter: { categoryList: { eq: $id } }
			first: $limit
			skip: $skip
		) {
			subcategory {
				subcategory
			}
			title
			slug
			categoryList {
				helpCategory
				description
				id
			}
			#### for getting heading 2 of the articles ####
			content {
				content {
					value
				}
			}
		}
		_allHelpArticlesMeta {
			count
		}
	}
`;

export const getAllHelpCategories = gql`
	query allHelpCategories($limit: IntType, $skip: IntType) {
		allHelpCategories(first: $limit, skip: $skip) {
			helpCategory
			description
			button {
				btntext
				btntype
				link
				size
			}
			id
		}
		_allHelpCategoriesMeta {
			count
		}
	}
`;

export const getAllHelpPostSlugs = gql`
	query allHelpArticles($limit: IntType, $skip: IntType) {
		allHelpArticles(first: $limit, skip: $skip) {
			slug
			faqModal
		}
		_allHelpArticlesMeta {
			count
		}
	}
`;

export const fetchHelpPostBySlug = gql`
	query ($slug: String!) {
		helpArticle(filter: { slug: { eq: $slug } }) {
			title
			slug
			faqModal
			metadata {
				title
				description
				date
			}
			categoryList {
				helpCategory
			}
			authors {
				name
				description
				image {
					image {
						responsiveImage(
							imgixParams: { fit: crop, w: 100, h: 100, auto: format }
						) {
							srcSet
							webpSrcSet
							sizes
							src
							width
							height
							aspectRatio
							alt
							title
							base64
						}
					}
				}
				authorInfo
				authorTitle
			}
			content {
				content {
					value
					links {
						__typename
						... on PdfDownloadRecord {
							id
							title
							pdf {
								url
							}
						}
						__typename
						... on ContentAdRecord {
							id
							title
							description
							buttonText
							image {
								responsiveImage(
									imgixParams: { fit: crop, w: 600, h: 400, auto: format }
								) {
									srcSet
									webpSrcSet
									sizes
									src
									width
									height
									aspectRatio
									alt
									title
									base64
								}
							}
						}
					}
					blocks {
						__typename
						... on ImageNewRecord {
							__typename
							id
							image {
								responsiveImage(
									imgixParams: { fit: clip, w: 600, h: 400, auto: format }
								) {
									srcSet
									webpSrcSet
									sizes
									src
									width
									height
									aspectRatio
									alt
									title
									base64
								}
							}
						}
						... on VideoRecord {
							__typename
							id
							url {
								url
							}
							youtubeVideoId
							title
						}
						... on FeedingGuideRecord {
							feedingGuide
							id
						}
						... on TableFieldRecord {
							id
							title
						}
					}
				}
			}
			sources {
				url
				linkText
				info
			}
			relatedQuestions {
				relatedHelpArticles {
					title
					categoryList {
						helpCategory
					}
					slug
					metadata {
						description
					}
				}
			}
			relatedSuccessStories {
				successStory {
					petName
					ownerName
					keywords {
						keyword
					}
					ailment {
						articleCategory
					}
					description
					coverImage {
						image {
							responsiveImage(
								imgixParams: { fit: crop, w: 600, h: 400, auto: format }
							) {
								srcSet
								webpSrcSet
								sizes
								src
								width
								height
								aspectRatio
								alt
								title
								base64
							}
						}
					}
				}
			}
			keyword {
				keyword
			}
		}
	}
`;
