export type FeatureList = {
	title: string;
	content: string;
	age: string[];
	allergies?: boolean;
	ailments: string[];
	fussy: boolean;
	health: string[];
	stools: string[];
	details?: {
		detailsButton: string;
		detailsInfo: string;
	};
	size?: string;
};

export const recipeFeaturesList: FeatureList[] = [
	{
		title: "Totally tasty renal dog food",
		content:
			"Everything your dog needs to stay in tip top shape, including delicious ingredients like peas, carrots and apple.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["renal failure"],
		fussy: false,
	},
	{
		title: "Helping limit kidney disease",
		content:
			"These Pure recipes have less sodium, less protein and less phosphorus to help limit progression of kidney disease.",
		age: [],
		health: [],
		stools: [],
		allergies: false,
		ailments: ["kidney disease"],
		fussy: false,
	},
	{
		title: "Keeping Leishmaniasis in mind",
		content:
			"Pure is great for dogs suffering with Leishmaniasis. Pure has plenty of wholesome low purine ingredients to help support %1 alongside their medication.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["leishmaniasis"],
		fussy: false,
	},
	{
		title: "Keeping Hypothyroidism in mind",
		content:
			"Pure is great for dogs with hypothyroidism and can be fed alongside their medication. The recipes have 100% natural ingredients with added vitamins and minerals.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["hypothyroidism"],
		fussy: false,
	},
	{
		title: "Keeping Addison's disease in mind",
		content:
			"Pure is gentle on the digestive system, perfect for supporting Addisons disease and includes absolutely no nasties.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["addisons disease"],
		fussy: false,
	},
	{
		title: "Keeping Cushing's in mind",
		content:
			"Pure is packed full of natural and high-quality ingredients and includes omega 3 fatty acids that can help promote healthy skin for dogs with Cushing's disease.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["cushings disease"],
		fussy: false,
	},
	{
		title: "Supporting %1’s epilepsy",
		content:
			"Pure is perfectly suited to dogs with epilepsy as alongside their medication, the 100% natural ingredients with added vitamins and minerals can help to manage the condition.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["epilepsy"],
		fussy: false,
	},
	{
		title: "Health at heart",
		content:
			"%1's recipes are low in sodium and contain ingredients such as taurine and vitamin E that help support heart conditions.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["a heart condition"],
		fussy: false,
	},
	{
		title: "Caring for cancer",
		content:
			"%1 can enjoy deliciously wholesome Pure with their cancer diagnosis. We’d recommend speaking to your vet before making any changes to their diet. ",
		age: [],
		allergies: false,
		ailments: ["cancer"],
		health: [],
		stools: [],
		fussy: false,
		details: {
			detailsButton: "More about Pure and cancer",
			detailsInfo:
				"Pure is full of natural ingredients and added vitamins and minerals to help support your dog throughout their treatment. However, due to their diagnosis, it’s best to check with your vet before you make any changes to their diet.",
		},
	},
	{
		title: "Keeping Arthritis in mind",
		content:
			"Pure is perfectly suited to dogs suffering with arthritis. It’s got plenty of wholesome ingredients to help support %1’s joints in conjunction with their medication",
		age: [],
		allergies: false,
		ailments: ["arthritis"],
		health: [],
		stools: [],
		fussy: false,
		details: {
			detailsButton: "supporting arthritis",
			detailsInfo:
				"We’ve added omega 3 oils, chondroitin and glucosamine that can help support joints. Additionally Pure can help keep their overall health in tip top shape thanks to our wholesome ingredients.",
		},
	},
	{
		title: "Perfect for pancreatitis",
		content:
			"%1's Pure is low in fat and has balanced omega 3 and 6 to keep pancreatitis flare ups at bay.",
		age: [],
		allergies: false,
		ailments: ["pancreatitis"],
		health: [],
		stools: [],
		fussy: false,
		details: {
			detailsButton: "Why omega 3 and 6?",
			detailsInfo:
				"Omega 3 has well-known anti-inflammatory properties. It contains an active ingredient called DHA, which is great for your pup’s skin and coat, as well as brain function. Omega 6 is naturally found within the fats in meat, and balancing the two is essential for a healthy diet.",
		},
	},
	{
		title: "Soothe sensitive skin",
		content:
			"Vitamins E, C and B6 as well as omega 3 and 6 from linseed are all in %1's Pure to soothe %3 sensitive skin.",
		age: [],
		allergies: false,
		ailments: ["sensitive skin"],
		health: [],
		stools: [],
		fussy: false,
		details: {
			detailsButton: "Why omega 3 and 6?",
			detailsInfo:
				"Omega 3 is the ultimate ingredient to aid healthy skin, as it feeds the skin’s good bacteria. Omega 6 is naturally found within the fats in meat, and balancing the two is essential for a healthy diet.",
		},
	},
	{
		title: "Settle a sensitive stomach",
		content:
			"%1's Pure has balanced fibre levels, natural prebiotic chicory root and omega 3 to promote healthy digestion.",
		age: [],
		allergies: false,
		ailments: ["a sensitive stomach"],
		health: ["digestion"],
		stools: [],
		fussy: false,
		details: {
			detailsButton: "What’s prebiotic chicory root?",
			detailsInfo:
				"Chicory is a plant that contains the active ingredient inulin. It’s this active ingredient that’s a food source for the healthy bacteria in the gut. Prebiotic chicory root also stops bad bacteria from attaching itself to the gut, optimising your pup’s digestive process.",
		},
	},
	{
		title: "Tailored for tear stains",
		content:
			"%1's Pure can help in keeping those troublesome tear stains at bay – it’s healthy, high quality and has absolutely nothing artificial.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["tear stains"],
		fussy: false,
	},
	{
		title: "Say Bye to UTIs",
		content:
			"%1's Pure contains cranberries to support healthy bladder function. You can always add extra water to Pure to keep your pup hydrated too.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: ["UTIs"],
		fussy: false,
	},
	{
		title: "Designed for diabetes",
		content:
			"We’ve made sure %1's Pure is high in fibre, low in fat, includes complex carbs and no nasties whatsoever to help support %3 diabetes.",
		age: [],
		allergies: false,
		ailments: ["diabetes"],
		fussy: false,
		health: [],
		stools: [],
		details: {
			detailsButton: "Learn about complex carbs",
			detailsInfo:
				"Paired with fibre, complex carbs create a slow, gentle release of sugar into the bloodstream which helps to avoid sugar rushes from occurring. Complex carbs, such as rice, take a lot longer to break down into sugars too, helping to stabilise your pooch’s blood sugar levels. ",
		},
	},
	{
		title: "Calming %1’s colitis",
		content:
			"%1's Pure has prebiotic chicory root, natural omega 3 and 6, and is low in fat to help keep %3 colitis under control.",
		age: [],
		allergies: false,
		ailments: ["colitis"],
		fussy: false,
		health: [],
		stools: [],
		details: {
			detailsButton: "What’s prebiotic chicory root?",
			detailsInfo:
				"Chicory is a plant that contains the active ingredient inulin. It’s this active ingredient that’s a natural food source for the healthy bacteria in the gut. Prebiotic chicory root also stops bad bacteria from attaching itself to the gut, optimising your pup’s digestive process.",
		},
	},
	{
		title: "Healthier anal glands",
		content:
			"%1's Pure is ideal for dogs that are sensitive to anal gland issues. It includes natural omega 3 and prebiotic chicory root to help prevent flare ups.",
		age: [],
		allergies: false,
		ailments: ["anal gland issues"],
		fussy: false,
		health: [],
		stools: [],
		details: {
			detailsButton: "More on omega 3",
			detailsInfo:
				"Omega 3 has well-known anti-inflammatory properties. We use omega 3 from algae, meaning it’s 100% natural and totally sustainable. It contains an active ingredient called DHA, which is great for your pup’s skin and coat, as well as brain function. Omega 3 from algae is also much easier for your pup to absorb.",
		},
	},
	{
		title: "Create super stools",
		content:
			"Balanced fibre, plenty of delicious veg and high quality meat all help %1 create the perfect poop.",
		age: [],
		allergies: false,
		ailments: [],
		health: [],
		stools: ["too dry", "a little wet", "really sloppy"],
		fussy: false,
	},
	{
		title: "Fresh and healthy",
		content:
			"%1's Pure includes vitamin E, no added sugar and much more to promote tip top oral health.",
		age: [],
		allergies: false,
		ailments: [],
		health: ["oral"],
		stools: ["too dry", "a little wet", "really sloppy"],
		fussy: false,
	},
	{
		title: "Restore balance",
		content:
			"Slow-release energy from healthy carbs to balance %1's energy levels, paired with glucosamine and chondroitin for healthy joints.",
		age: [],
		allergies: false,
		health: ["mobility"],
		stools: [],
		ailments: [],
		fussy: false,
	},
	{
		title: "Calming and wholesome",
		content:
			"Perfectly balanced with all the right vitamins and antioxidants known to help reduce stress and anxiety.",
		age: [],
		allergies: false,
		health: ["behavioural"],
		stools: [],
		ailments: [],
		fussy: false,
	},
	{
		title: "Delicious food %2 deserves",
		content:
			"Fussy dogs love the taste of Pure, we’re sure it’ll have %1's tail wagging in no time.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: true,
	},
	{
		title: "Senior Suitable",
		content:
			"Each portion is packed full of goodness including glucosamine and chondroitin to support %1's joints long into %3 senior life.",
		age: ["senior"],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: false,
		details: {
			detailsButton: "More on joint support",
			detailsInfo:
				"Natural glucosamine and chondroitin are a match made in heaven when it comes to joint support. Both materials are naturally present in your pup’s cartilage, but can deteriorate over time, so including them in their diet can help to support them into their senior years. Both ingredients are natural and glucosamine has anti-inflammatory properties too.",
		},
	},
	{
		title: "Perfectly portioned",
		content:
			"Crafted with your pets in mind, their tasty Pure will be lovingly hand-prepared here in Yorkshire.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: false,
		size: "a little skinny",
	},
	{
		title: "Perfectly portioned",
		content:
			"Crafted with your pets in mind, their tasty Pure will be lovingly hand-prepared here in Yorkshire.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: false,
		size: "a bit chubby",
	},
	{
		title: "Perfectly portioned",
		content:
			"Crafted with your pets in mind, their tasty Pure will be lovingly hand-prepared here in Yorkshire.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: false,
		size: "very overweight",
	},
	{
		title: "Investing in %1's future",
		content:
			"Pure includes gut friendly ingredients and antioxidants so you can invest in their ongoing health.",
		age: ["puppy", "adult", "senior"],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: false,
	},
	{
		title: "Perfectly portioned",
		content:
			"Crafted with your pets in mind, their tasty Pure will be lovingly hand-prepared here in Yorkshire.",
		age: [],
		allergies: false,
		health: [],
		stools: [],
		ailments: [],
		fussy: false,
		size: "just right",
	},
];
