import { gql } from "@apollo/client";

export const FEEDING_QUERY = gql`
	query GetFeedingGuide($type: String, $pet: PetInput) {
		feedingGuide(type: $type, pet: $pet) {
			old {
				type
				weight
				food
				water
				meat
			}
			new {
				base_calories
				treat_calories
				required_calories
				scoop_amount
				calories_per_day
			}
		}
	}
`;
