import { gql } from "@apollo/client";

import {
	BENEFITS_BAR_FIELDS,
	EXTRAS_FIELDS,
	FULL_WIDTH_FIELDS,
	IMAGE_TEXT_FIELDS,
	QUOTES_FIELDS,
	REVIEWS_FIELDS,
	STATISTIC_PANEL_FIELDS,
	SUCCESS_STORIES_FIELDS,
} from "./landingpageDato";

export const getAllRecipesQuery = gql`
	query allRecipes {
		allRecipes(
			first: 100
			filter: {
				recipeType: { in: ["dogs", "treats", "chew", "accessory", "vegi"] }
			}
		) {
			slug
			title
			subtitle
			handle
			recipeType
			description
			productDescription
			protein
			outOfStock
			showInShop
			information {
				title
				description
			}
			ingredientInformation
			ashAmount
			calciumAmount
			phosphorusAmount
			fatAmount
			fibreAmount
			kcalAmount
			proteinAmount
			nutritionalAdditives
			discontinued
			isABundle
			hideBundleItems
			isAVariant
			totalPriceOfAllProducts
			productsInBundle {
				slug
				title
				subtitle
				handle
				recipeType
				description
				productDescription
				protein
				outOfStock
				information {
					title
					description
				}
				ingredientInformation
				ashAmount
				calciumAmount
				phosphorusAmount
				fatAmount
				fibreAmount
				kcalAmount
				proteinAmount
				nutritionalAdditives
				discontinued
				callouts {
					feature
				}
				feedingGuide
				unitMeasurement
				variants {
					weight
					sku
					availableToBuy
					oldPrice
					productImage {
						url
					}
				}
				images {
					alt
					height
					width
					format
					url
					responsiveImage(
						imgixParams: {
							fit: crop
							crop: focalpoint
							w: 1000
							h: 600
							auto: format
						}
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
				advertImage {
					alt
					height
					width
					format
					url
					responsiveImage(
						imgixParams: {
							fit: crop
							crop: focalpoint
							w: 500
							h: 200
							auto: format
						}
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
			callouts {
				feature
			}
			feedingGuide
			unitMeasurement
			variants {
				weight
				title
				sku
				availableToBuy
				useAnotherRecipeAsVariant
				oldPrice
				recipeToUseAsVariant {
					handle
				}
				productImage {
					url
					responsiveImage(
						imgixParams: {
							fit: crop
							crop: focalpoint
							w: 1000
							h: 600
							auto: format
						}
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
			images {
				alt
				height
				width
				format
				url
				responsiveImage(
					imgixParams: {
						fit: crop
						crop: focalpoint
						w: 1000
						h: 600
						auto: format
					}
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			advertImage {
				alt
				height
				width
				format
				url
				responsiveImage(
					imgixParams: {
						fit: crop
						crop: focalpoint
						w: 500
						h: 200
						auto: format
					}
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
		}
	}
`;

export const getAllRecipePages = gql`
	query getAllRecipePages($limit: IntType, $skip: IntType) {
		allRecipes(first: $limit, skip: $skip) {
			slug
		}
		_allRecipesMeta {
			count
		}
	}
`;

export const getRecipes = gql`
	query getRecipe($handle: String) {
		recipe(filter: { handle: { eq: $handle } }) {
			metadata {
				title
				description
			}
			slug
			handle
			title
			subtitle
			description
			recipeType
			tag
			showInShop
			discontinued
			unitMeasurement
			images {
				responsiveImage(
					imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			variants {
				weight
				sku
				availableToBuy
				productImage {
					url
				}
			}
			outOfStock
		}
	}
`;

export const getRecipePage = gql`
	${IMAGE_TEXT_FIELDS}
	${BENEFITS_BAR_FIELDS}
	${REVIEWS_FIELDS}
	${QUOTES_FIELDS}
	${SUCCESS_STORIES_FIELDS}
	${FULL_WIDTH_FIELDS}
	${EXTRAS_FIELDS}
	${STATISTIC_PANEL_FIELDS}
	query getRecipePage($slug: String) {
		recipe(filter: { slug: { eq: $slug } }) {
			metadata {
				title
				description
			}
			slug
			handle
			title
			subtitle
			description
			ingredientInformation
			protein
			proteinAmount
			fatAmount
			fibreAmount
			ashAmount
			kcalAmount
			nutritionalAdditives
			recipeType
			tag
			showInShop
			discontinued
			unitMeasurement
			callouts {
				feature
			}
			feedingGuide
			extraDescription
			extraDescriptionTitle
			images {
				responsiveImage(
					imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			variants {
				weight
				sku
				availableToBuy
				useAnotherRecipeAsVariant
				oldPrice
				recipeToUseAsVariant {
					handle
				}
			}
			outOfStock
			sections {
				__typename
				...ImageTextFields
				...BenefitsBarFields
				...ReviewsFields
				...QuotesFields
				...SuccessStoriesFields
				...FullWidthFields
				...ExtrasFields
				...StatisticPanelFields
			}
			isABundle
			totalPriceOfAllProducts
			productsInBundle {
				metadata {
					title
					description
				}
				slug
				handle
				title
				subtitle
				description
				ingredientInformation
				protein
				proteinAmount
				fatAmount
				fibreAmount
				ashAmount
				kcalAmount
				nutritionalAdditives
				recipeType
				tag
				showInShop
				discontinued
				callouts {
					feature
				}
				feedingGuide
				extraDescription
				extraDescriptionTitle
				images {
					responsiveImage(
						imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
				variants {
					weight
					sku
					availableToBuy
					useAnotherRecipeAsVariant
					recipeToUseAsVariant {
						handle
					}
				}
				outOfStock
				sections {
					__typename
					...ImageTextFields
					...BenefitsBarFields
					...ReviewsFields
					...QuotesFields
					...SuccessStoriesFields
					...FullWidthFields
					...ExtrasFields
				}
			}
		}
	}
`;

export const getRecipeCategoriesSlugs = gql`
	query getRecipeCategoriesSlugs {
		allRecipeCategories {
			slug
			title
			pageDescription
		}
		_allRecipeCategoriesMeta {
			count
		}
	}
`;

export const fetchRecipeDataByCategory = gql`
	query getNonSubscriptionCategoriesRecipes($tag: String) {
		allRecipes(first: 100, filter: { tag: { eq: $tag } }) {
			title
			tag
			slug
			handle
			recipeType
			unitMeasurement
			callouts {
				feature
			}
			feedingGuide
			extraDescription
			extraDescriptionTitle
			isABundle
			totalPriceOfAllProducts
			isAVariant
			productsInBundle {
				handle
			}
			images {
				alt
				height
				width
				format
				url
				responsiveImage(
					imgixParams: {
						fit: crop
						crop: focalpoint
						w: 480
						h: 480
						auto: format
					}
				) {
					base64
					src
				}
			}
			variants {
				weight
				sku
				availableToBuy
				useAnotherRecipeAsVariant
				oldPrice
				recipeToUseAsVariant {
					handle
				}
				title
			}
			outOfStock
		}
		_allRecipesMeta(filter: { tag: { eq: $tag } }) {
			count
		}
	}
`;
