import { gql } from "@apollo/client";

export const fetchAllBlogPosts = gql`
	query allBlogs($limit: IntType, $skip: IntType) {
		allBlogs(first: $limit, skip: $skip) {
			title
			slug
			metadata {
				description
				date
			}
			categoryList {
				articleCategory
				id
			}
			coverImage {
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 400, h: 200, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
		}
		_allBlogsMeta {
			count
		}
	}
`;

export const fetchAllBlogPostsList = gql`
	query allBlogs($limit: IntType, $skip: IntType) {
		allBlogs(first: $limit, skip: $skip, filter: { hidden: { eq: false } }) {
			title
			slug
			metadata {
				description
				date
			}
			categoryList {
				articleCategory
				id
			}
			coverImage {
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 400, h: 200, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
		}
		_allBlogsMeta {
			count
		}
	}
`;

export const getBlogCategories = gql`
	{
		allArticleCategories {
			articleCategory
			id
		}
		_allArticleCategoriesMeta {
			count
		}
	}
`;

export const fetchBlogPostsByCategory = gql`
	query ($id: ItemId, $limit: IntType, $skip: IntType) {
		allBlogs(
			filter: { categoryList: { eq: $id }, hidden: { eq: false } }
			first: $limit
			skip: $skip
		) {
			title
			slug
			metadata {
				description
			}
			categoryList {
				articleCategory
				id
			}
			coverImage {
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 900, h: 600, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
		}
		_allBlogsMeta(filter: { categoryList: { eq: $id } }) {
			count
		}
	}
`;

export const fetchBlogPostBySlug = gql`
	query ($slug: String!) {
		blog(filter: { slug: { eq: $slug } }) {
			title
			addDixaChatBot
			slug
			metadata {
				title
				description
				date
			}
			seo {
				title
				twitterCard
				description
			}
			hidden
			coverImage {
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 900, h: 600, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
			categoryList {
				articleCategory
				id
			}
			content {
				content {
					value
					links {
						__typename
						... on PdfDownloadRecord {
							id
							title
							pdf {
								url
							}
						}
						__typename
						... on ContentAdRecord {
							id
							title
							description
							buttonText
							image {
								responsiveImage(
									imgixParams: { fit: crop, w: 900, h: 600, auto: format }
								) {
									srcSet
									webpSrcSet
									sizes
									src
									width
									height
									aspectRatio
									alt
									title
									base64
								}
							}
						}
					}
					blocks {
						__typename
						... on ImageNewRecord {
							__typename
							id
							image {
								responsiveImage(
									imgixParams: { fit: crop, w: 600, h: 400, auto: format }
								) {
									srcSet
									webpSrcSet
									sizes
									src
									width
									height
									aspectRatio
									alt
									title
									base64
								}
							}
						}
						... on VideoRecord {
							__typename
							id
							url {
								url
							}
							youtubeVideoId
							title
						}
						... on TableFieldRecord {
							id
							title
						}
						... on InlineHtmlRecord {
							id
							html
						}
						... on FeedingGuideRecord {
							feedingGuide
							id
						}
					}
				}
			}
			authors {
				name
				description
				image {
					image {
						responsiveImage(
							imgixParams: { fit: crop, w: 300, h: 300, auto: format }
						) {
							srcSet
							webpSrcSet
							sizes
							src
							width
							height
							aspectRatio
							alt
							title
							base64
						}
					}
				}
				authorInfo
			}
			sources {
				url
				linkText
				info
			}
			relatedArticles {
				relatedArticle {
					title
					slug
					categoryList {
						articleCategory
					}
					metadata {
						description
					}
					coverImage {
						image {
							responsiveImage(
								imgixParams: { fit: crop, w: 400, h: 300, auto: format }
							) {
								srcSet
								webpSrcSet
								sizes
								src
								width
								height
								aspectRatio
								alt
								title
								base64
							}
						}
					}
				}
			}
			popupModal {
				popup {
					heading
					body
					ctaButtonText
					ctaUrl
					voucherCode
					illustration {
						responsiveImage(
							imgixParams: { fit: crop, w: 70, h: 70, auto: format }
						) {
							srcSet
							webpSrcSet
							sizes
							src
							width
							height
							aspectRatio
							alt
							title
							base64
						}
					}
				}
			}
			popupTimer
		}
	}
`;
