// TODO type this file
import { format, addDays, isWeekend, subDays, addBusinessDays } from "date-fns";

import { CustomerAddress } from "@/types/AccountLocal";

import { bankHolidays } from "../consts";
import { Voucher } from "../types";

export const makeVoucherDisplayValue = (voucher: Voucher) => {
	return `${voucher.type === "fixed" ? "£" : ""}${voucher?.amount || ""}${
		voucher.type === "percentage" ? "%" : ""
	}`;
};

export function numberWithCommas(x: number) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function trim(text: string, length = 150, type = "letters") {
	if (!text) {
		return "";
	}
	const ending = "...";
	const stripped = text.replace(/<[^>]*>?/gm, "");
	if (stripped.length > length) {
		//trim the string to the maximum length
		const trimmedString = stripped.substring(0, length - ending.length);

		if (type === "words") {
			return (
				stripped
					.substring(
						0,
						Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
					)
					.replace(/,\s*$/, "") + ending
			);
		} else {
			return trimmedString + ending;
		}
	} else {
		return stripped;
	}
}

export function capitalize(val: string) {
	if (typeof val !== "string") {
		return val;
	}

	return val?.charAt(0).toUpperCase() + val?.slice(1);
}

export const monetize = (num: number) => {
	return `£${num.toLocaleString("gb-GB", {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	})}`;
};

export function titleCase(val: string) {
	if (typeof val !== "string") {
		return val;
	}

	return val.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
		return c.toUpperCase();
	});
}

export function titleWordCase(val: string) {
	const words = val?.toLowerCase()?.trim().split(" ");

	return words
		.map((word) => {
			return word?.[0]?.toUpperCase() + word.substring(1);
		})
		.join(" ");
}

export function formatAddressFromObject(
	address?: Partial<CustomerAddress>,
	oneLine: boolean = true
) {
	if (!address) {
		return "";
	}
	if (oneLine) {
		return `${address?.line_1}, ${
			address?.line_2 ? `${address?.line_2},` : ""
		} ${address?.city}, ${address?.country}, ${address?.postcode}`;
	}

	return (
		<>
			<div>{address?.line_1}</div>

			{address?.line_2 ? <div>{address?.line_2}</div> : ""}

			<div>{address?.city}</div>

			<div>{address?.country}</div>

			<div>{address?.postcode}</div>
		</>
	);
}

export function fromSentence(sentence: string | [], takeNone = false) {
	if (Array.isArray(sentence)) {
		return sentence;
	}
	if (takeNone && sentence === "none") {
		return [];
	} else if (sentence) {
		let commaList = sentence.replace(" and ", ", ");
		commaList = commaList.replace(/, /g, ",");

		return commaList.split(",");
	} else {
		return [];
	}
}

export const toSentence = (
	arr: Array<string>,
	addNone = false,
	capitalizeItems = false,
	extraText = ""
) => {
	if ((arr?.length || 0) < 1) {
		return "";
	}
	const arrayToUse = !Array.isArray(arr) ? fromSentence(arr) : arr;
	const finalArray = capitalizeItems
		? arrayToUse.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
		: arrayToUse;

	const finalArrayAddNone = addNone
		? finalArray.filter(function (item) {
				return item !== "none";
			})
		: finalArray;

	let value =
		finalArrayAddNone.slice(0, -2).join(", ") +
		(finalArrayAddNone.slice(0, -2).length ? ", " : "") +
		finalArrayAddNone.slice(-2).join(" and ");

	if (addNone && !value) {
		value = "none";
	}

	if (extraText) {
		return extraText + " " + value;
	} else {
		return value;
	}
};

export function age(num: number, type: "months" | "normal") {
	const year = Math.floor(num / 12);
	let months = "months";
	if (num - Math.floor(num) * 12 === 1) {
		months = "month";
	}
	if (type === "months") {
		return num + " " + months;
	} else if (num < 13) {
		return num + " " + months;
	} else {
		if (year < 2) {
			return year + " year " + (num - 12) + " " + months;
		} else {
			return year + " years";
		}
	}
}

export function slideUp(target: HTMLElement, duration = 500) {
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = "0";
	target.style.paddingTop = "0";
	target.style.paddingBottom = "0";
	target.style.marginTop = "0";
	target.style.marginBottom = "0";

	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
		//alert("!");
	}, duration);
}

export function slideDown(
	target: HTMLElement,
	duration = 500,
	displayContents: boolean
) {
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;

	if (display === "none") display = !displayContents ? "block" : "contents";

	target.style.display = display;
	const height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = "0";
	target.style.paddingTop = "0";
	target.style.paddingBottom = "0";
	target.style.marginTop = "0";
	target.style.marginBottom = "0";
	target.offsetHeight;
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}

export function slideToggle(
	target: HTMLElement,
	duration = 500,
	displayContents = false
) {
	if (window.getComputedStyle(target).display === "none") {
		return slideDown(target, duration, displayContents);
	} else {
		return slideUp(target, duration);
	}
}

export function transformContent(
	content: string,
	gender: "male" | "female",
	name: string
) {
	if (!content) {
		return "";
	}

	let pro1 = "he";
	let pro2 = "his";
	let pro3 = "him";
	if (gender === "female") {
		pro2 = "her";
		pro1 = "she";
		pro3 = "her";
	}

	const replacements = [name, pro2, pro3, pro1];

	const newcontent = content.replace(/%(\d+)/g, (_, n) => replacements[+n - 1]);

	return newcontent;
}

export function nextWorkingDate(
	startDate: Date | number = new Date(),
	toAdd: number = 1,
	before: boolean = false,
	now: boolean = true,
	useWorkingDays: boolean = true
): Date {
	let start = startDate;

	if (now) {
		if (parseInt(format(start, "H")) > 11) {
			start = addDays(start, 1);
		}
		while (
			bankHolidays.includes(format(start, "yyyy-MM-dd")) ||
			isWeekend(start)
		) {
			start = addDays(start, 1);
		}
	}

	let end;

	if (useWorkingDays) {
		end = addBusinessDays(start, toAdd);
	} else {
		end = addDays(start, toAdd);
	}

	while (bankHolidays.includes(format(end, "yyyy-MM-dd")) || isWeekend(end)) {
		if (before) {
			end = subDays(end, 1);
		} else {
			end = addDays(end, 1);
		}
	}

	return end;
}

export const removeDuplicatesFromArray = (a: string[]) => {
	const seen: Record<string, number> = {};

	const out: string[] = [];

	const len = a.length;

	let j = 0;

	for (let i = 0; i < len; i++) {
		const item = a[i];

		if (seen[item as keyof typeof seen] !== 1) {
			seen[item as keyof typeof seen] = 1;
			out[j++] = item;
		}
	}

	return out;
};

// Randomisation algorithm as array.sort is bad : https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export const shuffle = <T,>(array: T[]): T[] => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array;
};

export function squash(text: string) {
	const content = new String(text);

	// remove all html elements
	let re = /(<.+?>)/gi;
	let plain = content.replace(re, "");
	re = /(&.+?;)/gi;
	plain = plain.replace(re, "");

	// remove duplicated words
	const plainWords = plain.split(" ");
	const deduped = [...new Set(plainWords)];
	const dedupedStr = deduped.join(" ");

	// remove short and less meaningful words
	let result = dedupedStr.replace(
		/\b(\.|,|the|a|an|and|am|all|you|I|to|if|of|off|me|my|on|in|it|is|at|as|we|do|be|has|but|was|so|no|not|or|up|for)\b/gi,
		""
	);

	const words = result.split(" ");

	const stopWords = [
		"i",
		"me",
		"my",
		"myself",
		"we",
		"our",
		"ours",
		"ourselves",
		"you",
		"your",
		"yours",
		"yourself",
		"yourselves",
		"he",
		"him",
		"his",
		"himself",
		"she",
		"her",
		"hers",
		"herself",
		"it",
		"its",
		"itself",
		"they",
		"them",
		"their",
		"theirs",
		"themselves",
		"what",
		"which",
		"who",
		"whom",
		"this",
		"that",
		"these",
		"those",
		"am",
		"is",
		"are",
		"was",
		"were",
		"be",
		"been",
		"being",
		"have",
		"has",
		"had",
		"having",
		"do",
		"does",
		"did",
		"doing",
		"a",
		"an",
		"the",
		"and",
		"but",
		"if",
		"or",
		"because",
		"as",
		"until",
		"while",
		"of",
		"at",
		"by",
		"for",
		"with",
		"about",
		"against",
		"between",
		"into",
		"through",
		"during",
		"before",
		"after",
		"above",
		"below",
		"to",
		"from",
		"up",
		"down",
		"in",
		"out",
		"on",
		"off",
		"over",
		"under",
		"again",
		"further",
		"then",
		"once",
		"here",
		"there",
		"when",
		"where",
		"why",
		"how",
		"all",
		"any",
		"both",
		"each",
		"few",
		"more",
		"most",
		"other",
		"some",
		"such",
		"no",
		"nor",
		"not",
		"only",
		"own",
		"same",
		"so",
		"than",
		"too",
		"very",
		"s",
		"t",
		"can",
		"will",
		"just",
		"don",
		"should",
		"now",
	];

	const res = [];

	for (let i = 0; i < words.length; i++) {
		const theWord = words[i].toLowerCase();
		if (!stopWords.includes(theWord)) {
			res.push(words[i]);
		}
	}

	result = res.join(" ");

	//remove newlines, and punctuation
	result = result.replace(/\.|,|\?|-|—|\n/g, "");
	//remove repeated spaces
	result = result.replace(/[ ]{2,}/g, " ");

	result = result
		.replace(/\\n/g, "\\n")
		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, "\\&")
		.replace(/\\r/g, "\\r")
		.replace(/\\t/g, "\\t")
		.replace(/\\b/g, "\\b")
		.replace(/\\f/g, "\\f");
	// remove non-printable and other non-valid JSON chars
	result = result.replace(/[\u0000-\u0019]+/g, "");

	return result;
}

export const getUrlParameter = (name: string) => {
	const transformedName = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
	const regex = new RegExp("[\\?&]" + transformedName + "=([^&#]*)");
	const results = regex.exec(location.search);

	return results === null
		? ""
		: decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const getSource = (currentSource?: string) => {
	if (!window) {
		return;
	}

	if (getUrlParameter("utm_source")) {
		if (
			getUrlParameter("utm_source").toLowerCase().includes("facebook") ||
			getUrlParameter("utm_source").toLowerCase().includes("instagram")
		) {
			return "facebook";
		}
	}

	if (getUrlParameter("utm_medium") && getUrlParameter("utm_source")) {
		if (
			getUrlParameter("utm_medium").toLowerCase() === "cpc" &&
			getUrlParameter("utm_source").toLowerCase() === "google"
		) {
			return "paid search";
		} else if (getUrlParameter("utm_medium").toLowerCase().includes("email")) {
			return "email";
		}
	}

	if (document?.referrer && !currentSource) {
		if (
			[
				"facebook",
				"instagram",
				"twitter",
				"youtube",
				"pinterest",
				"tiktok",
			].some((v) => document.referrer.toLowerCase().includes(v))
		) {
			return "social";
		} else if (document.referrer.includes("google")) {
			return "organic";
		} else if (!document.referrer.includes("purepetfood.com")) {
			return "referral";
		}
	}

	if (!currentSource) {
		return "direct";
	}

	return;
};

export const createSentenceFromList = (
	names: string[],
	possessive = true,
	shortForm = true
) => {
	return names
		?.map((name, index) =>
			index === names.length - 1
				? `${name}${possessive ? "'s" : ""}`
				: index === names.length - 2
					? `${name} ${shortForm ? "&" : "and"} `
					: `${name}, `
		)
		.join("");
};

export const maskEmailAddress = (email: string | undefined) => {
	// Replaces email address with *'s
	if (!email) return;

	const [emailPrefix, domain] = email.split("@");

	if (emailPrefix.length <= 2) {
		return email;
	}

	const firstChar = emailPrefix[0];
	const lastChar = emailPrefix[emailPrefix.length - 1];

	const masked = firstChar + "*".repeat(emailPrefix.length - 2) + lastChar;

	return masked + "@" + domain;
};
