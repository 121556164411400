export const getLocalStorage = (key: string) => {
	if (typeof window === "undefined") {
		return null;
	}

	try {
		const data = window.localStorage.getItem(key);

		if (data) {
			if (data === "undefined") {
				return undefined;
			}

			return JSON.parse(data);
		}

		return null;
	} catch (e) {
		console.log(e);
		return null;
	}
};

export const setLocalStorage = <T>(key: string, value: T) => {
	try {
		// console.log("SET LOCAL STORAGE", key, JSON.stringify(value))
		typeof window !== "undefined" &&
			window.localStorage.setItem(key, JSON.stringify(value));
	} catch (e) {
		console.log(e);
	}
};
