import NextLink from "next/link";
import c from "classnames";

import { Icon, IconOptions } from "@/components/FormElements/Icon";

import Styles from "./link.module.scss";

type Props = {
	children: React.ReactNode;
	classname?: string;
	href: string;
	icon?: IconOptions;
};

export const Link = ({ children, href, classname, icon }: Props) => {
	return (
		<NextLink href={href}>
			<a className={c(classname, Styles.link)}>
				{icon && <Icon className={Styles.icon} icon={icon} />}
				{children}
			</a>
		</NextLink>
	);
};
