/* eslint-disable indent */
import { create } from "zustand";
import { addDays, differenceInCalendarDays, format, parseISO } from "date-fns";

import {
	CustomerAPIResponse,
	CustomerDeliveryAPIResponse,
	CustomerPetSuitableRecipeResponse,
	RAFInformation,
	RafInformationReferralScheme,
} from "@/types/Account";
import {
	AllCustomerDeliveries,
	CustomerDeliveryTrackingStatus,
	CustomerLocal,
	CustomerPet,
	CustomerPetSuitableRecipes,
	DeliveryAfterNextInformation,
	NextDeliveryInformation,
} from "@/types/AccountLocal";
import { HealthReport, HealthReportResponse } from "@/types/healthReports";
import { RecipeSampleOverview } from "@/types/Samples";
import { getPronouns } from "@/hooks/usePersonalPronouns";

import { getLocalStorage, setLocalStorage } from "./localStorage";

import {
	getDeliveryAfterNextDeliveryList,
	getNextDeliveryList,
	hasCardExpired,
} from "../helpers";

type AccountDataStore = {
	user: CustomerLocal | null;
	paymentMethods: CustomerLocal["paymentMethods"] | null;
	setUser: (user: CustomerAPIResponse) => void;
	updatePaymentMethods: (
		methods: CustomerLocal["paymentMethods"] | null
	) => void;
	updateUser: (user: Partial<CustomerLocal> | null) => void;

	nextDelivery: NextDeliveryInformation[];
	deliveryAfterNext: DeliveryAfterNextInformation[];

	userSuitableRecipes: CustomerPetSuitableRecipes;
	setUserSuitableRecipes: (recipes: CustomerPetSuitableRecipeResponse) => void;

	userDeliveries: AllCustomerDeliveries[];
	setUserDeliveries: (deliveries: CustomerDeliveryAPIResponse) => void;

	activePet: () => CustomerPet | null;
	activePetId: number | null;
	setActivePet: (name: AccountDataStore["activePetId"]) => void;

	isLoading: boolean;
	setIsLoading: (loading: boolean) => void;

	hasChangedDeliveryDate: boolean;
	setHasChangedDeliveryDate: (hasChanged: boolean) => void;

	petHealthReports: HealthReport;
	setPetHealthReports: (reports: HealthReportResponse) => void;

	locallyStoredData: Partial<CustomerLocal> | null;
	setLocallyStoredData: (
		locallyStoredData: Partial<CustomerLocal> | null
	) => void;

	restartPlanDeliveryDate: Date | null;
	setRestartPlanDeliveryDate: (date: Date | null) => void;

	oneOffBoxDeliveryDate: Date | null;
	setOneOffBoxDeliveryDate: (oneOffBoxDeliveryDate: Date | null) => void;
	oneOffBoxDeliveryAddress: string | null;
	setDeliveriesLoading: (loading: boolean) => void;
	deliveriesLoading: boolean;
	setOneOffBoxDeliveryAddress: (
		oneOffBoxDeliveryAddress: string | null
	) => void;

	availableRecipeSamples: {
		[petId: string]: RecipeSampleOverview[];
	};

	setAvailableRecipeSamples: (
		petId: string,
		sample: RecipeSampleOverview[]
	) => void;

	historicalRecipesTried: {
		[petId: string]: RecipeSampleOverview[];
	};

	historicalRecipesTriedCategory: {
		[petId: string]: string;
	};

	setHistoricalRecipesTried: (
		petId: string,
		sample: RecipeSampleOverview[],
		category: string
	) => void;

	orderTrackingData: {
		[trackingInfoDeliveryId: number]: {
			trackingInfo?: CustomerDeliveryTrackingStatus;
			trackingInfoTime?: string;
			trackingLate?: boolean;
			isFetchingTrackingInfo?: boolean;
			trackingCourier?: string;
			trackingCode?: string;
		};
	};

	setTrackingInfo: (
		info: {
			trackingInfoDeliveryId: number;
			trackingInfo?: CustomerDeliveryTrackingStatus;
			trackingInfoTime?: string | undefined;
			trackingLate?: boolean;
			isFetchingTrackingInfo?: boolean;
			trackingCourier?: string;
			trackingCode?: string;
		} | null
	) => void;

	upsellPlanPageVisits: number;

	setUpsellPlanPageVisits: (visitCount: number) => void;

	rafSchemes?: RAFInformation;
	setRafSchemes: (list: RAFInformation) => void;
};

const REPORT_FREQUENCY_IN_DAYS = 0; //42;

export const useAccountDataStore = create<AccountDataStore>((set, get) => ({
	upsellPlanPageVisits: parseInt(
		getLocalStorage("switchPlanPageVisits") || "0"
	),
	setUpsellPlanPageVisits: (visitCount) => {
		setLocalStorage("switchPlanPageVisits", visitCount);
		set(() => ({
			upsellPlanPageVisits: visitCount,
		}));
	},

	rafSchemes: undefined,
	setRafSchemes: (rafSchemes) => {
		set(() => ({
			rafSchemes,
		}));
	},

	orderTrackingData: {},

	setTrackingInfo: (data) => {
		if (data) {
			const { trackingInfoDeliveryId, ...info } = data;

			const currentTrackingData = get().orderTrackingData;
			set(() => ({
				orderTrackingData: {
					...currentTrackingData,
					[trackingInfoDeliveryId]: info,
				},
			}));

			return;
		}

		set(() => ({ orderTrackingData: {} }));
	},

	deliveriesLoading: false,
	setDeliveriesLoading: (deliveriesLoading) => {
		set(() => ({ deliveriesLoading }));
	},
	restartPlanDeliveryDate: null,
	setRestartPlanDeliveryDate: (restartPlanDeliveryDate) => {
		set(() => ({ restartPlanDeliveryDate }));
	},
	oneOffBoxDeliveryDate: null,
	setOneOffBoxDeliveryDate: (oneOffBoxDeliveryDate) => {
		set(() => ({ oneOffBoxDeliveryDate }));
	},
	oneOffBoxDeliveryAddress: null,
	setOneOffBoxDeliveryAddress: (oneOffBoxDeliveryAddress) => {
		set(() => ({ oneOffBoxDeliveryAddress }));
	},
	// default to true to avoid flash of content on load
	isLoading: true,
	setIsLoading: (isLoading) =>
		set(() => ({
			isLoading,
		})),

	locallyStoredData: null,
	setLocallyStoredData: (locallyStoredData) => {
		set(() => ({ locallyStoredData }));
	},

	historicalRecipesTried: {},

	historicalRecipesTriedCategory: {},

	setHistoricalRecipesTried: (petId, sample, category) => {
		const currentSamples = get().historicalRecipesTried;
		const currentCategoryList = get().historicalRecipesTriedCategory;

		set(() => ({
			historicalRecipesTried: {
				...currentSamples,
				[petId]: sample,
			},
			historicalRecipesTriedCategory: {
				...currentCategoryList,
				[petId]: category,
			},
		}));
	},

	availableRecipeSamples: {},

	setAvailableRecipeSamples: (petId, sample) => {
		const currentSamples = get().availableRecipeSamples;

		set(() => ({
			availableRecipeSamples: {
				...currentSamples,
				[petId]: sample,
			},
		}));
	},

	hasChangedDeliveryDate: false,
	setHasChangedDeliveryDate: (hasChanged) =>
		set(() => ({
			hasChangedDeliveryDate: hasChanged,
		})),

	user: null,
	paymentMethods: null,

	userSuitableRecipes: [],
	setUserSuitableRecipes: (recipes) => {
		set(() => ({
			availableRecipeSamples: {},
			historicalRecipesTried: {},
			historicalRecipesTriedCategory: {},
			userSuitableRecipes: recipes.pets,
		}));
	},

	petHealthReports: [],
	setPetHealthReports: (healthReports: HealthReportResponse) => {
		const sortedData = healthReports?.pets.map(
			({ id, healthReports: petReports }) => {
				const sortedHealthReports = petReports
					.map((report) => ({
						...report,
						updated_at: new Date(report.updated_at),
						created_at: new Date(report.created_at),
					}))
					.sort((a, b) => {
						return b.updated_at.getTime() - a.updated_at.getTime();
					});

				const shouldRecommendTakingASurvey = sortedHealthReports?.[0]
					? differenceInCalendarDays(
							new Date(),
							sortedHealthReports[0].created_at
						) >= REPORT_FREQUENCY_IN_DAYS
					: true;

				const nextSurveyDue =
					sortedHealthReports?.[0] &&
					addDays(
						sortedHealthReports?.[0].created_at,
						REPORT_FREQUENCY_IN_DAYS
					);

				return {
					id,
					healthReports: sortedHealthReports,
					// use this to decide whether to show popups etc
					shouldRecommendTakingASurvey,
					// use this to show the user the date
					nextSurveyDue,
				};
			}
		);
		set(() => ({
			petHealthReports: sortedData as unknown as HealthReport,
		}));
	},

	userDeliveries: [],
	setUserDeliveries: (userDeliveries) => {
		const convertedDeliveries =
			userDeliveries.pets?.reduce((prev: AllCustomerDeliveries[], curr) => {
				const deliveries =
					curr.subscription.deliveries?.map((delivery) => {
						return {
							...delivery,
							secondary_items:
								delivery.secondary_items.filter(({ sample }) => !sample) || [],
							samples:
								delivery.secondary_items.filter(({ sample }) => sample) || [],
							pet: {
								id: curr.id,
								breed: curr.breed,
								name: curr.name,
								gender: curr.gender,
								profile_picture: curr.profile_picture,
								fussy: curr.fussy,
							},
							delivery_date: new Date(delivery.delivery_date),
							billing_date: new Date(delivery.billing_date),
							processing_date:
								(delivery.processing_date &&
									new Date(delivery.processing_date)) ||
								undefined,
							readableDeliveryDate: format(
								new Date(delivery.delivery_date),
								"EEEE do LLLL"
							),
							oneOff: false,
						};
					}) || [];

				return deliveries ? prev.concat(deliveries) : prev;
			}, []) || [];

		const oneOffDeliveries =
			userDeliveries.deliveries?.map((delivery) => {
				return {
					...delivery,
					secondary_items:
						delivery.secondary_items.filter(({ sample }) => !sample) || [],
					samples:
						delivery.secondary_items.filter(({ sample }) => sample) || [],
					delivery_date: new Date(delivery.delivery_date),
					billing_date: new Date(delivery.billing_date),
					processing_date: new Date(delivery.processing_date),
					readableDeliveryDate: format(
						new Date(delivery.delivery_date),
						"EEEE do LLLL"
					),
					oneOff: true,
				};
			}) || [];

		return set(() => ({
			availableRecipeSamples: {},
			historicalRecipesTried: {},
			historicalRecipesTriedCategory: {},
			userDeliveries: convertedDeliveries
				.concat(oneOffDeliveries)
				.sort(
					({ delivery_date: a }, { delivery_date: b }) =>
						a.getTime() - b.getTime()
				),
		}));
	},

	updateUser: (user) => {
		if (user === null) {
			return set(() => ({
				user: null,
			}));
		}

		const storedUser = get().user;

		if (!storedUser) {
			return;
		}

		const newUser: CustomerLocal = {
			...storedUser,
			...user,
			shopify_id: user.shopify_id ? user.shopify_id : storedUser?.shopify_id,
		};

		return set(() => ({
			user: newUser,
		}));
	},

	updatePaymentMethods: (methods) => {
		if (!methods) {
			set({
				paymentMethods: null,
			});

			return;
		}

		const filteredMethods = get().paymentMethods?.filter(
			({ payment_id: currentPaymentId }) => {
				const currentMethodExists = methods?.find(
					({ payment_id: newCurrentPaymentId }) =>
						newCurrentPaymentId === currentPaymentId
				);

				if (currentMethodExists) {
					return false;
				}

				return true;
			}
		);

		set({
			paymentMethods: [
				...(methods ? methods : []),
				...(filteredMethods ? filteredMethods : []),
			],
		});
	},

	setUser: (user) => {
		const pets: CustomerPet[] = (user.pets || []).map((pet) => ({
			...pet,
			pronouns: getPronouns(pet.gender),
		}));

		let paymentMethods: CustomerLocal["paymentMethods"];

		try {
			paymentMethods = user.paymentMethods?.map(
				({ last_four_digits, expiry_date, payment_id, ...rest }) => ({
					...rest,
					payment_id,
					last_four_digits,
					expiry_date: expiry_date ? parseISO(expiry_date) : undefined,
					readableExpiryDate: expiry_date
						? format(parseISO(expiry_date), "MM/yy")
						: undefined,
					isDefault: user.paymentMethod?.payment_id === payment_id,
					isExpired: expiry_date ? hasCardExpired(expiry_date) : undefined,
				})
			);
		} catch (e) {
			console.log(e);
			paymentMethods = undefined;
		}

		const parsedCustomer: CustomerLocal = {
			...user,
			created_at: new Date(user.created_at),
			pets,
		};

		const nextDelivery = user ? getNextDeliveryList(parsedCustomer) || [] : [];

		const deliveryAfterNext = user
			? getDeliveryAfterNextDeliveryList(parsedCustomer) || []
			: [];

		return set(() => ({
			user: parsedCustomer,
			paymentMethods,
			nextDelivery,
			deliveryAfterNext,
		}));
	},

	activePet: () => {
		const activeId = get().activePetId;
		const user = get().user;

		if (!activeId || !user) {
			return null;
		}

		return user?.pets.find((pet) => pet.id === activeId) || null;
	},

	nextDelivery: [],

	deliveryAfterNext: [],

	activePetId: null,
	setActivePet: (activePetId) =>
		set(() => ({
			activePetId,
		})),
}));
