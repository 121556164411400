import { track } from "./analytics";

// Feature test
var hasStorage = (function () {
	try {
		localStorage.setItem("foo", "bar");
		localStorage.removeItem("foo");
		return true;
	} catch (exception) {
		return false;
	}
})();

export function save(key: string, value: any) {
	try {
		if (hasStorage) {
			localStorage.setItem(key, value);
		} else {
			alert(
				"Sorry, there is an issue with your browser when using our website. Please exit private browsing or upgrade your web browser to allow you to browse our website without issue."
			);
			if (
				typeof window !== "undefined" &&
				typeof window.analytics !== "undefined"
			) {
				track("Local Storage Error", {
					category: "Error",
					label: "Local Storage Error",
				});
			}
		}
	} catch (e) {
		return false;
	}
	return false;
}

export function remove(key: string) {
	if (hasStorage) {
		localStorage.removeItem(key);
		return true;
	}
	return false;
}

export function get(key: string) {
	if (hasStorage) {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			console.log(e);
			return false;
		}
	} else {
		return false;
	}
}
