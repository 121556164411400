// BANK HOLIDAYS
export const bankHolidays = [
	// 24
	"2024-01-01",
	"2024-04-01",
	"2024-12-25",
	"2024-12-26",
	// 25
	"2025-01-01",
	"2025-12-25",
	"2025-12-26",
	// 26
	"2026-01-01",
	"2026-12-25",
	"2026-12-28",
	// 27
	"2027-01-01",
	"2027-12-27",
	"2027-12-28",
	// 28
	"2028-01-03",
	"2028-12-25",
	"2028-12-26",
	// 29
	"2029-01-01",
	"2029-12-25",
	"2029-12-26",
];
