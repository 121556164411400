// TODO type this file
// @ts-nocheck
import { BodyShape, LifeStages, Pet, RecipeOverview } from "@/types/index";

import { initializeApollo } from "./apolloClient";
import {
	BRANDS_QUERY,
	BREEDS_COUNT_QUERY,
	BREEDS_QUERY,
	PREDICTED_WEIGHT_QUERY,
	RECIPE_QUERY,
	WEIGHT_QUERY,
} from "./queries";

import { dietContentData } from "../data";

export async function getAllBreeds() {
	const apolloClient = initializeApollo();
	return await apolloClient
		.query({
			query: BREEDS_QUERY,
			context: { clientName: "subApp" },
		})
		.then((result) => {
			return result.data.breeds;
		});
}

export async function getAllBrands() {
	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: BRANDS_QUERY,
			context: { clientName: "subApp" },
		})
		.then((result) => {
			return result.data.GetBrands.map(({ brand, format }) => ({
				name: brand,
				format: format,
			}));
		});
}

type PredictedAdultWeightReturn = {
	predicted_weight: number;
};

export async function getPredictedAdultWeight({
	breed,
	weight,
	age,
}: {
	breed: string;
	weight: number;
	age: number;
}): Promise<PredictedAdultWeightReturn> {
	const apolloClient = initializeApollo();
	return await apolloClient
		.query({
			query: PREDICTED_WEIGHT_QUERY,
			variables: {
				breed,
				weight,
				age,
			},
			context: { clientName: "subApp" },
		})
		.then((result) => {
			return result.data.predictedWeight;
		})
		.catch((e) => {
			console.error(e);
		});
}

type BreedCountReturn = {
	id: string;
	name: string;
	active_count: number;
	total_count: number;
};

export async function getBreedCount(breed: string): Promise<BreedCountReturn> {
	const apolloClient = initializeApollo();
	return await apolloClient
		.query({
			query: BREEDS_COUNT_QUERY,
			variables: {
				name: breed,
			},
			context: { clientName: "subApp" },
		})
		.then((result) => {
			return result.data.breedCount;
		})
		.catch((e) => {
			console.error(e);
		});
}

type BreedWeightReturn = {
	adult_age: number;
	current_lifestage: LifeStages;
	estimated_weight: number;
	female_weight: number;
	male_weight: number;
	old_puppy_age: number;
};

export async function getBreedWeight(
	breed,
	age,
	gender,
	period?: "years",
	breedSelected = true
): Promise<BreedWeightReturn> {
	if (!breed || !age || !gender) {
		return false;
	}

	if (period === "years") {
		age = age * 12;
	}

	const minAge = Math.max(1, age);

	const apolloClient = initializeApollo();
	return await apolloClient
		.query({
			query: WEIGHT_QUERY,
			variables: {
				name: breed,
				age: Math.round(parseFloat(minAge)),
				gender: gender,
			},
			context: { clientName: "subApp" },
		})
		.then((result) => {
			return result.data.breedWeight;
		})
		.catch((e) => {
			console.error(e);
		});
}

function reduceBenefits(maxBenefitsOfType, recipes, maxBenefitsPerItem) {
	const recipeCount = {};

	return recipes.map(({ benefits, ...recipe }) => {
		let benefitsAddedToRecipe = 0;
		const benefitsFiltered = benefits.filter((benefit) => {
			if (
				recipeCount[benefit] === maxBenefitsOfType ||
				benefitsAddedToRecipe === maxBenefitsPerItem
			) {
				return;
			}

			benefitsAddedToRecipe++;
			recipeCount[benefit] = recipeCount[benefit]
				? recipeCount[benefit] + 1
				: 1;
			return true;
		});

		return {
			...recipe,
			benefits: benefitsFiltered,
		};
	});
}

export async function calculateRecipe(
	age: Pet["age"],
	allergies: Pet["allergies"],
	healthIssues: Pet["healthIssues"],
	fussyness: Pet["fussy"],
	weight: Pet["weight"],
	type: string,
	breed: string = "mixed",
	condition: BodyShape = "just right"
): Promise<RecipeOverview[]> {
	const variables = {
		type: type,
		pet: {
			age: Math.round(parseFloat(age)),
			weight: weight,
			breed: breed,
			fussy: fussyness,
			allergies: allergies,
			issues: healthIssues,
			size: condition,
		},
	};
	const apolloClient = initializeApollo();
	return await apolloClient
		.query({
			query: RECIPE_QUERY,
			variables: variables,
			context: { clientName: "subApp" },
		})
		// change value here to increase max amount of duplicate benefits & max amount of benefits per item
		// .then((result) =>
		//   reduceBenefits(
		//     MAX_BENEFITS_OF_SAME_TYPE,
		//     result.data.recipes[API_SWITCH],
		//     MAX_BENEFITS_PER_RECIPE
		//   )
		// )
		.then((result) => result.data.recipes);
}

export const dietContent = (diet, name, gender) =>
	dietContentData(name, gender)[diet];
