import {
	DestinationPlugin, Event, Result 
} from "@amplitude/analytics-types";
import { Experiment } from '@amplitude/experiment-js-client';

// FROM AMPLITUDE DOCS https://www.docs.developers.amplitude.com/data/sdk-plugins/#typescript_7
export class GTMPlugin implements DestinationPlugin {
	name = "google-tag-manager";
	type = "destination" as const;
	containerId: string;

	constructor (containerId: string) {
		this.containerId = containerId;
	}

	async setup (): Promise<void> {
		if (!window.dataLayer) {
			window.dataLayer = window.dataLayer || [];

			window.dataLayer.push({
				"gtm.start": new Date().getTime(),
				event: "gtm.js",
			});

			const head = document.getElementsByTagName("head")[0],
				script = document.createElement("script"),
				// DON'T REMOVE - This must be to catch an OS/browser issue where cases don't match
				// eslint-disable-next-line eqeqeq
				dataLayer = "datalayer" != "dataLayer"
					? "&l=" + "datalayer"
					: "";

			script.async = true;
			script.src =
				"https://www.googletagmanager.com/gtm.js?id=" +
				this.containerId +
				dataLayer;
			head.insertBefore(script, head.firstChild);
		}
	}

	async execute (event: Event): Promise<Result> {
		window.dataLayer.push({ event: event.event_type,
			...event });

		return {
			code: 200,
			event: event,
			message: "Event pushed onto GTM Data Layer",
		};
	}
}


