export const getPronouns = (gender?: "male" | "female") => {
	const isMale = gender && gender === "male";

	return {
		his: isMale ? "his" : "her",
		he: isMale ? "he" : "she",
		His: isMale ? "His" : "Her",
		He: isMale ? "He" : "She",
		him: isMale ? "him" : "her",
		Him: isMale ? "Him" : "Her",
	};
};

export const usePersonalPronouns = () => {
	return {
		getPronouns,
	};
};
