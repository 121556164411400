/* eslint-disable indent */
import { format } from "date-fns";
import capitalize from "lodash/capitalize";

import { CustomerPet } from "@/types/AccountLocal";

import {
	Checkout,
	CheckoutAPIResponse,
	CustomerPetAPIResponse,
} from "../types";

export const CreateCheckoutFromAPIData = (
	data: CheckoutAPIResponse
): Checkout => {
	return {
		address: data.address,
		sales_person_id: data.sales_person_id,
		completed: data.completed,
		completed_at: data.completed_at,
		customer:
			data.customer === null
				? undefined
				: {
						email: data.customer.email,
						first_name: data.customer.first_name,
						last_name: data.customer.last_name,
						phone: `${data.customer.phone}`,
						customer_id: data.customer.customer_id,
						total_orders: data.customer.total_orders,
						contact_via_email: data.customer.contact_via_email,
					},
		delivery_date: `${data.delivery_date}`,
		discount_code: data.discount_code,
		earliest_delivery_date: `${data.earliest_delivery_date}`,
		id: data.id,
		tests: data.tests,
		discounts: data.discounts,
		is_subscription: data.is_subscription,
		lineItems: data.products.map((product) => ({
			...product,
			...(product.pet
				? {
						pet: {
							...product.pet,
							activity: product.pet?.activity_level,
							age: product.pet?.age,
							subscription: product.pet?.subscription,
						},
					}
				: {}),
			items: product.items.map((item) => ({
				...item,
				id: item.product_id,
				product_id: `${item.product_id}`,
				variant_id: `${item.variant_id}`,
				subscription_variant_id: `${item.subscription_variant_id}`,
				protein: `${item.protein}`,
			})),
		})),
		pricing: data.pricing,
		properties: data.properties,
		referral_token: data.referral_token,
		stripe_payment_intent_client_secret:
			data.stripe_payment_intent_client_secret,
		stripe_payment_intent_id: data.stripe_payment_intent_id,
		stripe_setup_intent_client_secret: data.stripe_setup_intent_client_secret,
		stripe_setup_intent_id: data.stripe_setup_intent_id,
		use_terminal: data.use_terminal,
	};
};

export const convertPetAPIObjectToLocal = (
	pet: CustomerPetAPIResponse
): CustomerPet => {
	const next_delivery: CustomerPet["subscription"]["next_delivery"] =
		pet.subscription.next_delivery !== null
			? {
					...pet.subscription?.next_delivery,
					delivery_date: new Date(
						pet.subscription?.next_delivery.delivery_date
					),
					billing_date: new Date(pet.subscription?.next_delivery.billing_date),
					readableDeliveryDate: format(
						new Date(pet.subscription?.next_delivery.delivery_date),
						"do LLLL yyyy"
					),
				}
			: null;

	const delivery_after_next: CustomerPet["subscription"]["delivery_after_next"] =
		pet.subscription.delivery_after_next !== null
			? {
					...pet.subscription?.delivery_after_next,
					delivery_date: new Date(
						pet.subscription?.delivery_after_next.delivery_date
					),
					billing_date: new Date(
						pet.subscription?.delivery_after_next.billing_date
					),
					readableDeliveryDate: format(
						new Date(pet.subscription?.delivery_after_next.delivery_date),
						"do LLLL yyyy"
					),
				}
			: null;
	let tests = null;
	try {
		tests = JSON.parse(pet.subscription.tests);
	} catch (e) {}

	return {
		...pet,
		name: capitalize(pet.name),
		neutered: pet.neutered ? "yes" : "no",
		// @ts-ignore
		size: pet.size === "a little chubby" ? "a bit chubby" : pet.size,
		subscription: {
			...pet.subscription,
			next_delivery,
			delivery_after_next,
			tests,
		},
	};
};
