import { gql } from "@apollo/client";

export const getAllBreedCategoriesPath = gql`
	query allBreedCategories($limit: IntType, $skip: IntType) {
		allBreedCategories(first: $limit, skip: $skip) {
			slug
		}
		_allBreedCategoriesMeta {
			count
		}
	}
`;

export const getAllBreedCategoriesInfo = gql`
	query allBreedCategories($slug: String!) {
		allBreedCategories(filter: { slug: { eq: $slug } }) {
			id
			slug
			title
			pageTitle
			description
			pageDescription
		}
		_allBreedCategoriesMeta(filter: { slug: { eq: $slug } }) {
			count
		}
	}
`;

export const getAllBreeds = gql`
	query allBreeds($limit: IntType, $skip: IntType) {
		allBreeds(first: $limit, skip: $skip) {
			title
			breed
			metadata {
				description
			}
			slug
			breedImage {
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 680, h: 480, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
			infoOrDiet {
				__typename
				... on BreedDietRecord {
					id
					contentType
					commonAllergies
					commonAilments
					fussiness
				}
				... on BreedInfoRecord {
					id
					contentType
					lifespan
					weight
					height
					temperament
					commonColours {
						colour {
							hex
						}
					}
					breedDescription
					breedDescriptionImage {
						image {
							responsiveImage(
								imgixParams: { fit: crop, w: 680, h: 480, auto: format }
							) {
								srcSet
								webpSrcSet
								sizes
								src
								width
								height
								aspectRatio
								alt
								title
								base64
							}
						}
					}
					tags {
						title
						slug
					}
				}
			}
			relatedBreedArticle {
				relatedBreedArticle {
					slug
					infoOrDiet {
						__typename
						... on BreedDietRecord {
							contentType
						}
						... on BreedInfoRecord {
							contentType
						}
					}
				}
			}
		}
		_allBreedsMeta {
			count
		}
	}
`;

export const getAllBreedsSlugs = gql`
	query allBreeds($limit: IntType, $skip: IntType) {
		allBreeds(first: $limit, skip: $skip) {
			slug
		}
		_allBreedsMeta {
			count
		}
	}
`;

export const fetchBreedPostBySlug = gql`
	query ($slug: String!) {
		breed(filter: { slug: { eq: $slug } }) {
			title
			slug
			metadata {
				title
				description
				date
			}
			breedImage {
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 680, h: 480, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
			breed
			infoOrDiet {
				__typename
				... on BreedDietRecord {
					contentType
					commonAilments
					commonAllergies
					fussiness
				}
				... on BreedInfoRecord {
					contentType
					tags {
						title
						slug
					}
					lifespan
					weight
					height
					temperament
					commonColours {
						colour {
							hex
						}
					}
					breedDescription
					breedDescriptionImage {
						image {
							responsiveImage(
								imgixParams: { fit: crop, w: 680, h: 480, auto: format }
							) {
								srcSet
								webpSrcSet
								sizes
								src
								width
								height
								aspectRatio
								alt
								title
								base64
							}
						}
					}
				}
			}
			relatedBreedArticle {
				relatedBreedArticle {
					slug
				}
			}
			content {
				content {
					value
					links {
						__typename
						... on ContentAdRecord {
							id
							title
							description
							buttonText
							image {
								responsiveImage(
									imgixParams: { fit: crop, w: 680, h: 480, auto: format }
								) {
									srcSet
									webpSrcSet
									sizes
									src
									width
									height
									aspectRatio
									alt
									title
									base64
								}
							}
						}
					}
					blocks {
						__typename
						... on ImageNewRecord {
							__typename
							id
							image {
								responsiveImage(
									imgixParams: { fit: crop, w: 680, h: 480, auto: format }
								) {
									srcSet
									webpSrcSet
									sizes
									src
									width
									height
									aspectRatio
									alt
									title
									base64
								}
							}
						}
						... on VideoRecord {
							__typename
							id
							url {
								url
							}
							youtubeVideoId
							title
						}
						... on TableFieldRecord {
							id
							title
						}
						... on FeedingGuideRecord {
							feedingGuide
							id
						}
					}
				}
			}
		}
	}
`;
