import { gql } from "@apollo/client";

export const BREEDS_QUERY = gql`
	query GetBreeds {
		breeds {
			name
		}
	}
`;

export const BRANDS_QUERY = gql`
	query GetBrands {
		GetBrands {
			brand
			format
		}
	}
`;

export const BREEDS_COUNT_QUERY = gql`
	query GetBreedCount($name: String) {
		breedCount(name: $name) {
			id
			name
			active_count
			total_count
		}
	}
`;

export const WEIGHT_QUERY = gql`
	query GetBreedWeight($name: String, $age: Float, $gender: String) {
		breedWeight(name: $name, age: $age, gender: $gender) {
			estimated_weight
			current_lifestage
			adult_age
			old_puppy_age
			male_weight
			female_weight
		}
	}
`;

export const PREDICTED_WEIGHT_QUERY = gql`
	query GetPredictedWeight($breed: String, $age: Int, $weight: Float) {
		predictedWeight(breed: $breed, age: $age, weight: $weight) {
			predicted_weight
		}
	}
`;

export const RECIPE_QUERY = gql`
	query GetRecipes($type: String, $pet: PetInput) {
		recipes(type: $type, pet: $pet) {
			id
			name
			handle
			type
			category
			protein
			allergens
			health_warnings
			age_inappropriate
			size_inappropriate
			breed_inappropriate
			fussy_inappropriate
			pet_size_inappropriate
			discontinued
			show_in_funnel
			order
			suitable
			weight_order
			benefits
			tags
			created_at
			updated_at
			product {
				title
				image
				tags
				is_seasonal
				seasonal_start_date
				seasonal_end_date
				bundle_item_variants {
					id
					title
					sku
					weight
					price
					price_tests {
						name
						price
					}
				}
				variants {
					id
					title
					sku
					weight
					price
					price_tests {
						name
						price
					}
				}
			}
		}
	}
`;
