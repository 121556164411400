import React from "react";

export type HeaderNavType = "minimal" | "checkout" | "normal";
export type FooterType = "hidden" | null;
export type HeaderNavItem = {
	link: string;
	onClick: () => void;
	text: string;
};
export type HeaderNavItems = HeaderNavItem &
	{
		children?: HeaderNavItem[];
	}[];

export enum HeaderTypes {
	default = "default",
	funnel = "funnel",
	hero = "hero",
	heroMenu = "heroMenu",
	login = "login",
	overlay = "overlay",
	heroOverlay = "heroOverlay",
}

export enum FooterTypes {
	pure = "pure",
	learn = "learn",
	product = "product",
	information = "information",
}

export type AccordionProps = {
	disabled?: boolean;
	iconPosition?: "start" | "end";
	heading?: React.ReactNode | string;
	isNegative?: boolean;
	leftAlign?: boolean;
	id?: string;
	type?: "text" | "html";
	children: React.ReactNode;
	classList?: string[];
	autoOpen?: boolean;
	alternate?: boolean;
	modalRef?: React.RefObject<HTMLDivElement>;
	trackOpen?: boolean;
	inModal?: boolean;
	noMargin?: boolean;
	noHeadingPad?: boolean;
	noPad?: boolean;
	wrapperClassName?: string;
};

export enum SlapLabelVariants {
	default = "DEFAULT",
	subtle = "SUBTLE",
	attention = "ATTENTION",
	offers = "OFFERS",
	errors = "ERRORS",
	grey = "GREY",
}

export enum ModalVariants {
	default = "DEFAULT",
	info = "INFO",
}
