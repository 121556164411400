/* eslint-disable indent */
import { format, getDay } from "date-fns";

import { bankHolidays } from "../consts";
import { ProductMeasurementUnits } from "../types";

export const transformAge = (months?: number, years?: number) => {
	return (parseInt(years + "") * 12 || 0) + (parseInt(months + "") || 0);
};

export const getPetAgeLabel = (age: number) => {
	if (age < 12) {
		return "puppy";
	} else if (age > 84) {
		return "senior";
	}

	return "adult";
};

type GetItemWeightDisplayReturn<T> = T extends true
	? { value: number; unit: string }
	: T extends false
		? string
		: never;

type GetObj = true | false;

export function getItemWeightDisplay<T extends GetObj>(
	weight: string,
	getObj: T = false as T,
	alternateUnit?: ProductMeasurementUnits
): GetItemWeightDisplayReturn<T> {
	const number = Number(weight);

	if (isNaN(number) && !getObj) {
		return "" as GetItemWeightDisplayReturn<T>;
	}

	const obj = {
		value: number > 999 ? number / 1000 : number,
		unit:
			alternateUnit && alternateUnit === "none"
				? ""
				: alternateUnit && alternateUnit !== "grams"
					? alternateUnit
					: number > 999
						? "kg"
						: "g",
	};
	if (getObj) {
		return obj as GetItemWeightDisplayReturn<T>;
	}
	return `${obj.value}${alternateUnit === "bags" ? " " : ""}${
		obj.unit
	}` as GetItemWeightDisplayReturn<T>;
}

export const isValidDeliveryDate = (date: Date) => {
	if (bankHolidays.includes(format(date, "yyyy-MM-dd"))) {
		return false;
	}
	const day = getDay(date);
	return day !== 0 && day !== 6;
};

export const convertSelectedPlanToReadable = (selectedPlan: string) => {
	if (selectedPlan === "large") {
		return "Large plan";
	} else if (selectedPlan === "partial") {
		return "Partial plan";
	} else if (selectedPlan === "partial large") {
		return "Large partial plan";
	} else if (selectedPlan === "partial extra large") {
		return "Extra large partial plan";
	} else if (selectedPlan === "extra large") {
		return "Extra large plan";
	} else {
		return "All Pure plan";
	}
};
