export enum AmplitudeExperiments {
	RECIPEGRID = "recipe-grid",
	NOPROBLEMS = "no-problems",
	REVIEWPLAN = "review-plan",
	FEEDINGPLAN = "feeding-plan",
	PRICETEST = "price-test",
	SENSITIVE_CLARITY = "sensitive-clarity",
	RENAL_CLARITY = "renal-clarity",
	SHOW_MORE_EXTRAS = "show-more-extras",
	EXPRESS_CHECKOUT = "express-checkout",
	SHOW_BEST_PRICE = "cancel-show-best-price",
	CANCEL_VOUCHERS = "cancel-vouchers",
	WEIGHT_RANGE = "weight-range",
	CANCEL_GPT = "cancel-gpt",
	ASK_BRAND = "ask-brand",
}

export type ABVariants = Record<string, "treatment" | "control" | "off">;

export type ExperimentContext = {
	loading: boolean;
	variants: ABVariants;
};

export type ExperimentItem = {
	key: string;
	user_id?: string;
};
