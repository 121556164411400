/* eslint-disable import/order */
import c from "classnames";
import Countdown from "@/icons/Countdown.png";
import Minus from "@/icons/Minus.png";
import ThumbDown from "@/icons/ThumbDown.png";
import ThumbUp from "@/icons/ThumbUp.png";
import { Player } from "@lottiefiles/react-lottie-player";
import Calendar from "@/icons/Calendar.svg";
import Camera from "@/icons/Camera.svg";
import Play from "@/icons/Play.svg";
import BackArrow from "@/icons/BackArrow.svg";
import ForwardArrow from "@/icons/ForwardArrow.svg";
import IconFunnelBoy from "@/icons/funnel/icon-funnel-boy.png";
import IconFunnelChubby from "@/icons/funnel/icon-funnel-chubby.png";
import IconFunnelGirl from "@/icons/funnel/icon-funnel-girl.png";
import IconFunnelHealth from "@/icons/funnel/icon-funnel-health.png";
import IconFunnelMoreDogs from "@/icons/funnel/icon-funnel-more-dogs.png";
import IconFunnelMoreDogsYellow from "@/icons/funnel/icon-funnel-more-dogs-yellow.png";
import IconFunnelNeutered from "@/icons/funnel/icon-funnel-neutered.png";
import IconFunnelNotNeutered from "@/icons/funnel/icon-funnel-not-neutered.png";
import IconFunnelOverweight from "@/icons/funnel/icon-funnel-overweight.png";
import IconFunnelRight from "@/icons/funnel/icon-funnel-right.png";
import IconFunnelRunning from "@/icons/funnel/icon-funnel-running.png";
import IconFunnelSitting from "@/icons/funnel/icon-funnel-sitting.png";
import IconFunnelSkinny from "@/icons/funnel/icon-funnel-skinny.png";
import IconFunnelTreatsNone from "@/icons/funnel/icon-funnel-treats-none.png";
import IconFunnelTreatsLots from "@/icons/funnel/icon-funnel-treats-lots.png";
import IconFunnelTreatsFew from "@/icons/funnel/icon-funnel-treats-few.png";
import IconFunnelTreatsCouple from "@/icons/funnel/icon-funnel-treats-couple.png";
import PooDry from "@/icons/funnel/poo-dry.png";
import PooIdeal from "@/icons/funnel/poo-ideal.png";
import PooWet from "@/icons/funnel/poo-wet.png";
import PooSloppy from "@/icons/funnel/poo-sloppy.png";
import IconFunnelWalking from "@/icons/funnel/icon-funnel-walking.png";
import IconFunnelSprinting from "@/icons/funnel/icon-funnel-sprinting.png";
import IconFunnelPuppy from "@/icons/funnel/icon-funnel-puppy.png";
import IconFunnelOldPuppy from "@/icons/funnel/icon-funnel-old-puppy.png";
import IconFunnelAdult from "@/icons/funnel/icon-funnel-adult.png";
import IconFunnelSenior from "@/icons/funnel/icon-funnel-senior.png";
import IconCompleteTick from "@/icons/funnel/icon-complete-tick.png";
import IconCompleteWelcome from "@/icons/funnel/icon-complete-welcome.png";
import IconCompleteLoading from "@/icons/funnel/icon-complete-loading.png";
import IconPlus from "@/icons/icon-plus.svg";
import IconMinus from "@/icons/icon-minus.svg";
import IconTick from "@/icons/icon-tick.svg";
import ChevronDown from "@/icons/ChevronDown.svg";
import ChevronUp from "@/icons/ChevronUp.svg";
import ChevronLeft from "@/icons/ChevronLeft.svg";
import ChevronCircleRight from "@/icons/ChevronCircleRight.svg";
import ChevronRight from "@/icons/ChevronRight.svg";
import Swipe from "@/icons/swipe.svg";
import Close from "@/icons/Close.svg";
import FunnelComplete from "@/icons/FunnelComplete.svg";
import Star from "@/icons/star.svg";
import StarRatingEmpty from "@/icons/StarRatedEmpty.svg";
import StarRatingFull from "@/icons/StarRatedFull.svg";
import CreditCard from "@/icons/CreditCard.svg";
import User from "@/icons/User.svg";
import Lock from "@/icons/Lock.svg";
import Verified from "@/icons/verified.svg";
import Award from "@/icons/award.svg";
import Heart from "@/icons/heart.svg";
import Time from "@/icons/time.svg";
import Pie from "@/icons/pie.svg";
import Pound from "@/icons/pound.svg";
import Fire from "@/icons/fire.svg";
import Virus from "@/icons/virus.svg";
import Question from "@/icons/question.svg";
import Freeze from "@/icons/freeze.svg";
import Skull from "@/icons/skull.svg";
import Basket from "@/icons/iconBasket.svg";
import Pencil from "@/icons/Pencil.svg";
import Tools from "@/icons/tools.svg";
import Restart from "@/icons/restart.svg";
import Paw from "@/icons/paw.svg";
import Weight from "@/icons/Weight.svg";
import Treat from "@/icons/Treat.svg";
import Cutlery from "@/icons/Cutlery.svg";
import Neutered from "@/icons/Neutered.svg";
import Germ from "@/icons/Germ.svg";
import Health from "@/icons/Health.svg";
import CircleArrowLeft from "@/icons/CircleArrowLeft.svg";
import CircleArrowRight from "@/icons/CircleArrowRight.svg";
import LandingCake from "@/icons/landing/cake.png";
import IconTreatAttention from "@/icons/treats/treat-icons-attention.png";
import IconTreatEnjoyment from "@/icons/treats/treat-icons-enjoyment.png";
import IconTreatHealthy from "@/icons/treats/treat-icons-healthy.png";
import IconTreatImmunity from "@/icons/treats/treat-icons-immunity.png";
import IconTreatNatural from "@/icons/treats/treat-icons-natural.png";
import IconTreatRewards from "@/icons/treats/treat-icons-rewards.png";
import IconTreatSkin from "@/icons/treats/treat-icons-skin.png";
import IconCircleTick from "@/icons/icon-circle-tick.svg";
import LandingBackedByVets from "@/icons/landing/backed-by-vets.png";
import LandingCompleteAndBalanced from "@/icons/landing/complete-and-balanced.png";
import LandingDelivery from "@/icons/landing/delivery.png";
import LandingFreeDelivery from "@/icons/landing/free-delivery.png";
import LandingHappierDogs from "@/icons/landing/happier-dogs.png";
import LandingHealthyGoodness from "@/icons/landing/healthy-goodness.png";
import LandingHonestFood from "@/icons/landing/honest-food.png";
import LandingJustAddWater from "@/icons/landing/just-add-water.png";
import LandingLongerLives from "@/icons/landing/longer-lives.png";
import LandingNaturalIngredients from "@/icons/landing/natural-ingredients.png";
import LandingNaturallyTasty from "@/icons/landing/naturally-tasty.png";
import LandingNoNasties from "@/icons/landing/no-nasties.png";
import LandingTellUsAboutDog from "@/icons/landing/tell-us-about-dog.png";
import LandingValueRewards from "@/icons/landing/value-rewards.png";
import LandingSupportingImmunity from "@/icons/landing/supporting-immunity.png";
import LandingSkinAndCoat from "@/icons/landing/skin-and-coat.png";
import LandingMaintainAttention from "@/icons/landing/maintain-attention.png";
import LandingJointHealth from "@/icons/landing/joint-health.png";
import LandingBoxHearts from "@/icons/landing/box-hearts.png";
import LandingBowlPour from "@/icons/landing/bowl-pour.png";
import LandingCall from "@/icons/landing/call.png";
import LandingPin from "@/icons/landing/pin.png";
import LandingCalendar from "@/icons/landing/calendar.png";
import LandingAddDogYellow from "@/icons/landing/add-dog-yellow.png";
import LandingBowlWhiteScoop from "@/icons/landing/bowl-with-white-scoop.png";
import LandingBowlYellowScoop from "@/icons/landing/bowl-with-yellow-scoop.png";
import LandingBoxRedHearts from "@/icons/landing/box-with-red-hearts.png";
import LandingDroplet from "@/icons/landing/droplet-blue.png";
import LandingFreeDeliveryTruck from "@/icons/landing/free-delivery-truck.png";
import LandingPawYellow from "@/icons/landing/paw-yellow.png";
import LandingPiggyBankPink from "@/icons/landing/piggy-bank-pink.png";
import Upload from "@/icons/Upload.svg";
import DogGrey from "@/icons/DogGrey.png";
import Copy from "@/icons/Copy.svg";
import Search from "@/icons/search.svg";
import TrashCan from "@/icons/TrashCan.svg";
import PureCheckCircle from "@/icons/pure/check-circle.svg";
import PureCrossCircle from "@/icons/pure/cross-circle.svg";
import Styles from "./icon.module.scss";
import Peers from "@/icons/Peers.svg";
import Home from "@/icons/home.svg";
import Shop from "@/icons/shop.svg";
import Headset from "@/icons/Headset.svg";
import HeadsetYellow from "@/icons/HeadsetYellow.png";
import IconDogSillouhette from "@/icons/IconDogSillouhette.png";
import PurePlus from "@/icons/PurePlus.png";
import PureBasket from "@/icons/Basket.png";
import PureNextBox from "@/icons/Nextbox.png";
import ShoppingBasket from "@/icons/ShoppingBasket.svg";
import Warning from "@/icons/Warning.svg";
import HeartBowl from "@/icons/heart-bowl.png";
import SurpriseBowl from "@/icons/surprise-bowl.png";
import Truck from "@/icons/Truck.svg";
import LocationPin from "@/icons/LocationPin.svg";

import Visa from "@/icons/Visa.svg";
import MasterCard from "@/icons/Mastercard.svg";
import PayPal from "@/icons/PayPal.svg";
import EVRI from "@/icons/evriLogo.png";
import DPD from "@/icons/dpdLogo.png";

import GuideChat from "@/icons/fussyGuide/Chat.png";
import GuideFork from "@/icons/fussyGuide/Fork.png";
import GuideMeatballs from "@/icons/fussyGuide/Meatballs.png";
import GuidePuppy from "@/icons/fussyGuide/Puppy.png";

import GuideBulb from "@/icons/beginnersGuide/LightbulbIdea.png";
import GuidePour from "@/icons/beginnersGuide/Pour.png";
import GuideTimer from "@/icons/beginnersGuide/Timer.png";

import Facebook from "@/icons/socialMedia/Facebook.png";
import Instagram from "@/icons/socialMedia/Instagram.png";
import Twitter from "@/icons/socialMedia/Twitter.png";
import TikTok from "@/icons/socialMedia/Tiktok.svg";

import FacebookRound from "@/icons/socialMedia/FacebookRound.png";
import InstaRound from "@/icons/socialMedia/InstaRound.png";
import TikTokRound from "@/icons/socialMedia/TikTokRound.png";

import SantaHat from "@/icons/xmas/SantaHat.png";
import Bauble from "@/icons/xmas/bauble.png";
import Present from "@/icons/xmas/Present.png";
import SnowFlake from "@/icons/xmas/snowFlake.png";

import AnimateTick from "@/icons/animations/tick.json";
import AnimateWave from "@/icons/animations/wave.json";
import AnimateAccount from "@/icons/animations/account.json";

import LoadingSpinner from "@/icons/loading--dark.gif";
import LoadingSpinnerLight from "@/icons/loading.gif";

import YellowWarningCircle from "@/icons/PureYellowWarningCircle.png";
import Samples from "@/icons/Samples.png";
import Volume from "@/icons/Volume.svg";
import Share from "@/icons/Share.svg";
import Mute from "@/icons/Mute.svg";

import PlayBtn from "@/icons/PlayBtn.svg";

import RedTruck from "@/icons/RedTruck.png";
import InfoBubble from "@/icons/InfoBubble.svg";
import PiggyBank from "@/icons/PiggyBank.png";
import MoneyCoin from "@/icons/money-coin.png";
import OfferBadge from "@/icons/offer-badge.png";
import Phone from "@/icons/Phone.png";
import WaterPlus from "@/icons/WaterPlus.png";
import FullScreenChevronDown from "@/icons/FullScreenChevronDown.svg";

import FullScreenChevronUp from "@/icons/FullScreenChevronUp.svg";

import CancelCalendar from "@/icons/Cancellations/Calendar.png";
import CancelGreenCalendar from "@/icons/Cancellations/GreenCalendar.png";
import CancelChat from "@/icons/Cancellations/Chat.png";
import CancelChatHeadset from "@/icons/Cancellations/ChatHeadset.png";
import CancelFreeTruck from "@/icons/Cancellations/FreeTruck.png";
import CancelPresent from "@/icons/Cancellations/Present.png";
import CancelPlusStar from "@/icons/Cancellations/PlusStar.png";
import CancelSurpriseBowl from "@/icons/Cancellations/SurpriseBowl.png";
import CancelHeartBox from "@/icons/Cancellations/Heartbox.png";
import CancelHeartBowl from "@/icons/Cancellations/HeartBowl.png";
import CancelLeaf from "@/icons/Cancellations/Leaf.png";
import CancelSurpriseLight from "@/icons/Cancellations/SurpriseLight.png";
import FreeSample from "@/icons/Cancellations/FreeSample.png";
import LargeBackArrow from "@/icons/Cancellations/LargeBackArrow.png";

import Phone2 from "@/icons/Phone2.png";
import DiscountTag from "@/icons/DiscountTag.png";
import PiggyBankYellow from "@/icons/PiggyBankYellow.png";

import Maintenance from "@/icons/maintenance.png";

import FullPure from "@/icons/feedingPlan/food100.png";
import TwoThirdPure from "@/icons/feedingPlan/food66.png";
import HalfPure from "@/icons/feedingPlan/food50.png";
import OneThirdPure from "@/icons/feedingPlan/food33.png";
import DNA from "@/icons/renal/dna.png";
import Droplet from "@/icons/renal/droplet.png";
import HealthPlus from "@/icons/renal/plus.png";
import HeartPlus from "@/icons/HeartPlus.svg";

import SensitiveArrowDrop from "@/icons/sensitive/arrow drop.png";
import SensitiveArrow from "@/icons/sensitive/arrow.png";
import SensitiveBubbleDrop from "@/icons/sensitive/bubble drop.png";
import SensitiveDNA from "@/icons/sensitive/dna.png";
import SensitiveLeaf from "@/icons/sensitive/leaf.png";
import SensitiveLeaf2 from "@/icons/sensitive/leaf2.png";
import SensitiveLightningBolt from "@/icons/sensitive/lightning bolt.png";
import SensitiveMicrobe from "@/icons/sensitive/microbe.png";
import SensitiveOrgan from "@/icons/sensitive/organ.png";
import SensitivePoo from "@/icons/sensitive/poo.png";
import SensitiveScales from "@/icons/sensitive/scales.png";
import SensitiveShakerArrow from "@/icons/sensitive/shaker arrow.png";
import SensitiveShaker from "@/icons/sensitive/shaker.png";
import SensitiveWater from "@/icons/sensitive/water.png";

export const Icons = {
	/** Sensitive info icons */
	SensitiveArrowDrop,
	SensitiveArrow,
	SensitiveBubbleDrop,
	SensitiveDNA,
	SensitiveLeaf,
	SensitiveLeaf2,
	SensitiveLightningBolt,
	SensitiveMicrobe,
	SensitiveOrgan,
	SensitivePoo,
	SensitiveScales,
	SensitiveShakerArrow,
	SensitiveShaker,
	SensitiveWater,
	/** // Sensitive info icons */

	FullPure,
	TwoThirdPure,
	HalfPure,
	OneThirdPure,
	HeartPlus,
	DNA,
	Droplet,
	HealthPlus,
	Phone2,
	LargeBackArrow,
	CancelChatHeadset,
	FreeSample,
	ChevronCircleRight,
	DiscountTag,
	PiggyBankYellow,
	FullScreenChevronDown,
	FullScreenChevronUp,
	Mute,
	PlayBtn,
	Volume,
	Share,
	CancelSurpriseLight,
	CancelLeaf,
	CancelHeartBox,
	CancelHeartBowl,
	CancelCalendar,
	CancelGreenCalendar,
	CancelChat,
	CancelFreeTruck,
	CancelPresent,
	CancelPlusStar,
	CancelSurpriseBowl,
	Phone,
	WaterPlus,
	InfoBubble,
	PiggyBank,
	MoneyCoin,
	OfferBadge,
	RedTruck,
	YellowWarningCircle,
	Samples,
	Truck,
	SurpriseBowl,
	HeartBowl,
	Visa,
	MasterCard,
	PayPal,
	Warning,
	SantaHat,
	Bauble,
	Present,
	SnowFlake,
	LoadingSpinner,
	LoadingSpinnerLight,
	PureBasket,
	PureNextBox,
	Countdown,
	Minus,
	ThumbDown,
	ThumbUp,
	IconDogSillouhette,
	GuideChat,
	GuideFork,
	GuideMeatballs,
	GuidePuppy,
	Facebook,
	TikTok,
	Instagram,
	Twitter,
	FacebookRound,
	InstaRound,
	TikTokRound,
	GuideBulb,
	GuidePour,
	GuideTimer,
	PureCheckCircle,
	ShoppingBasket,
	PureCrossCircle,
	Copy,
	Play,
	Upload,
	DogGrey,
	TrashCan,
	PooDry,
	PooIdeal,
	PooSloppy,
	PooWet,
	LandingCake,
	IconFunnelTreatsNone,
	IconFunnelTreatsLots,
	IconFunnelTreatsFew,
	IconFunnelWalking,
	IconFunnelTreatsCouple,
	BackArrow,
	ForwardArrow,
	Peers,
	IconFunnelBoy,
	IconFunnelChubby,
	IconFunnelSitting,
	IconFunnelRight,
	IconFunnelRunning,
	IconFunnelMoreDogs,
	IconFunnelMoreDogsYellow,
	IconFunnelNeutered,
	IconFunnelNotNeutered,
	IconFunnelOverweight,
	IconFunnelGirl,
	IconFunnelSkinny,
	IconFunnelSprinting,
	IconFunnelHealth,
	IconFunnelSenior,
	IconFunnelPuppy,
	IconFunnelOldPuppy,
	IconFunnelAdult,
	IconCompleteTick,
	IconCompleteWelcome,
	IconCompleteLoading,
	IconPlus,
	IconMinus,
	IconTick,
	ChevronUp,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	Swipe,
	Calendar,
	Camera,
	Close,
	FunnelComplete,
	Star,
	StarRatingEmpty,
	StarRatingFull,
	Award,
	Heart,
	Time,
	Pie,
	Pound,
	Freeze,
	Fire,
	Virus,
	Skull,
	Question,
	Pencil,
	Paw,
	Home,
	Shop,
	Weight,
	Treat,
	Cutlery,
	Germ,
	Health,
	CircleArrowLeft,
	CircleArrowRight,
	Neutered,
	IconTreatAttention,
	IconTreatEnjoyment,
	IconTreatHealthy,
	IconTreatImmunity,
	IconTreatNatural,
	IconTreatRewards,
	IconTreatSkin,
	IconCircleTick,
	Basket,
	LandingBackedByVets,
	LandingCompleteAndBalanced,
	LandingDelivery,
	LandingFreeDelivery,
	LandingHappierDogs,
	LandingHealthyGoodness,
	LandingHonestFood,
	LandingJustAddWater,
	LandingLongerLives,
	LandingNaturalIngredients,
	LandingNaturallyTasty,
	LandingNoNasties,
	LandingTellUsAboutDog,
	LandingMaintainAttention,
	LandingSkinAndCoat,
	LandingSupportingImmunity,
	LandingValueRewards,
	LandingJointHealth,
	LandingBoxHearts,
	LandingBowlPour,
	LandingCall,
	LandingPin,
	LandingCalendar,
	LandingAddDogYellow,
	LandingBowlWhiteScoop,
	LandingBowlYellowScoop,
	LandingBoxRedHearts,
	LandingDroplet,
	LandingFreeDeliveryTruck,
	LandingPawYellow,
	LandingPiggyBankPink,
	Verified,
	Fragment: "Fragment",
	User,
	Lock,
	CreditCard,
	Tools,
	Restart,
	Search,
	AnimateTick,
	AnimateWave,
	AnimateAccount,
	PurePlus,
	Headset,
	HeadsetYellow,
	EVRI,
	DPD,
	Maintenance,
	LocationPin,
};

export type IconOptions = keyof typeof Icons;

type Props = {
	icon: IconOptions;
	height?: string;
	width?: string;
	noFill?: boolean;
	heightToOccupy?: string;
	viewbox?: string;
	animation?: boolean;
} & React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

export const Icon = ({
	icon,
	height = "24",
	width = "24",
	noFill = false,
	heightToOccupy = "",
	viewbox,
	animation = false,
	style,
	...props
}: Props) => {
	const Selected: any = Icons[icon];

	if (icon === Icons.Fragment) {
		return <></>;
	}

	return (
		<span
			{...props}
			style={{
				...style,
				height: heightToOccupy ? heightToOccupy : height,
			}}
			className={c(Styles.wrapper, props.className, {
				[Styles.noFill]: noFill,
			})}
		>
			{animation ? (
				<Player
					autoplay
					loop
					src={Selected}
					style={{
						height: height,
						width: width,
					}}
				></Player>
			) : Selected.src ? (
				<img
					src={Selected.src}
					height={height || Selected.height}
					width={width || Selected.width}
					alt="icon"
				/>
			) : (
				<Selected
					style={{
						fill: "currentcolor",
						height,
						width,
					}}
					height={height}
					width={width}
					viewBox={"0 0 48 48"}
				/>
			)}
		</span>
	);
};
