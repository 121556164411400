// TODO type this file
// @ts-nocheck
import { useMemo } from "react";

import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	ApolloLink,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

let apolloClient;

function createApolloClient(initialState) {
	// Create First Link
	const shopify = new HttpLink({
		uri:
			process.env.NEXT_PUBLIC_STOREFRONT_DOMAIN + "/api/2021-01/graphql.json",
		headers: {
			"X-Shopify-Storefront-Access-Token":
				process.env.NEXT_PUBLIC_STOREFRONT_ACCESS,
		},
		// other link options...
	});

	// Create Second Link
	const subApp = new HttpLink({
		uri: process.env.NEXT_PUBLIC_SUBAPP_DOMAIN + "/graphql",
		headers: {
			"Content-Type": "application/json",
		},
		// other link options...
	});

	// Create Third Link
	const checkoutApp = new HttpLink({
		uri: process.env.NEXT_PUBLIC_CHECKOUT_DOMAIN + "/graphql",
		headers: {
			"Content-Type": "application/json",
		},
	});

	// Create Fourth Link
	const dato = new HttpLink({
		uri: process.env.NEXT_PUBLIC_DATOCMS_URL,
		headers: {
			authorization: `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_ACCESS_TOKEN}`,
			"Content-Type": "application/json",
		},
	});

	// Create Fifth Link
	const checkoutAPI = new HttpLink({
		uri: process.env.NEXT_PUBLIC_CHECKOUT_API_DOMAIN + "/graphql",
		headers: {
			"Content-Type": "application/json",
		},
	});
	// Create Sixth Link
	const datoPreview = new HttpLink({
		uri: process.env.NEXT_PUBLIC_DATOCMS_URL_PREVIEW,
		headers: {
			authorization: `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_PREVIEW_SECRET}`,
			"Content-Type": "application/json",
		},
	});

	// Create Seventh Link
	const production = new HttpLink({
		uri: process.env.NEXT_PUBLIC_PRODUCTION_DOMAIN + "/graphql",
		headers: {
			"Content-Type": "application/json",
		},
		// other link options...
	});

	const linksForSeventhSplit = ApolloLink.split(
		(operation) => operation.getContext().clientName === "production", // Routes the query to the proper client
		production
	);

	const linksForSixthSplit = ApolloLink.split(
		(operation) => operation.getContext().clientName === "datoPreview", // Routes the query to the proper client
		datoPreview,
		linksForSeventhSplit
	);

	const linksForFifthSplit = ApolloLink.split(
		(operation) => operation.getContext().clientName === "checkoutAPI", // Routes the query to the proper client
		checkoutAPI,
		linksForSixthSplit
	);

	const linksForFourthSplit = ApolloLink.split(
		(operation) => operation.getContext().clientName === "dato", // Routes the query to the proper client
		dato,
		linksForFifthSplit
	);

	const linksForThirdSplit = ApolloLink.split(
		(operation) => operation.getContext().clientName === "shopify", // Routes the query to the proper client
		shopify,
		linksForFourthSplit
	);

	const linksForSecondSplit = ApolloLink.split(
		(operation) => operation.getContext().clientName === "checkoutApp", // Routes the query to the proper client
		checkoutApp,
		linksForThirdSplit
	);

	return new ApolloClient({
		connectToDevTools: process.browser,
		ssrMode: typeof window === "undefined",
		link: new RetryLink().split(
			(operation) => operation.getContext().clientName === "subApp", // Routes the query to the proper client
			subApp,
			linksForSecondSplit
		),
		cache: new InMemoryCache({ addTypename: false }).restore(
			initialState || {}
		),
	});
}

export function initializeApollo(initialState = null) {
	const _apolloClient = apolloClient ? apolloClient : createApolloClient();

	if (!process.browser) {
		return _apolloClient;
	}

	// If your page has Next.js data fetching methods that use Apollo Client,
	// the initial state gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract();

		// Restore the cache using the data passed from
		// getStaticProps/getServerSideProps combined with the existing cached data
		_apolloClient.cache.restore({ ...existingCache, ...initialState });
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === "undefined") return _apolloClient;

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;
	return _apolloClient;
}

export function useApollo(initialState) {
	const store = useMemo(() => initializeApollo(initialState), [initialState]);
	return store;
}
