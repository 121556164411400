import { isAfter, lastDayOfMonth } from "date-fns";

import { CustomerLocal } from "@/types/AccountLocal";

export const hasCardExpired = (expiry_date: string) => {
	const monthOfExpiry = expiry_date && new Date(`01/${expiry_date}`);
	const lastDayOfValidity = monthOfExpiry && lastDayOfMonth(monthOfExpiry);
	const cardHasExpired = lastDayOfValidity
		? isAfter(new Date(), lastDayOfValidity)
		: false;

	return cardHasExpired;
};
