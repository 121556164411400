export const dietContentData = (name: string, gender: string) => ({
	"kibble biscuits": {
		title: {
			line1: "Ditch the",
			line2: "boring brown biscuits",
		},
		description:
			"Get ready to see " +
			name +
			" thrive. By switching " +
			name +
			" from dry, boring, brown biscuits to Pure they’ll be much healthier, live longer and visit the vet less. Say so long to those boring brown biscuits!",
		image1: "/images/pure-pet-food.jpg",
		image1AltText: "Pure Pet Food",
		image2: "/images/kibble-dog-food.jpg",
		image2AltText: "Kibble dog food",
		goodTitle: "The perks of Pure",
		badTitle: "Kick the kibble",
		sections: [
			{
				icon: "Star",
				bad: false,
				title: "Naturally dried",
				longTitle: "Pure is designed for better health",
				content:
					"We gently dry high-quality ingredients to remove moisture and lock in the nutrients, without sacrificing quality. Just add water, stir and serve.",
			},
			{
				icon: "Heart",
				bad: false,
				title: "Invest in " + name + "’s health",
				longTitle: "Full of natural, high quality ingredients",
				content:
					"Our dogs are part of the family and deserve real food. They'll enjoy better bellies, shinier coats, increased vitality and much more. Healthier dogs live longer lives.",
			},
			{
				icon: "Award",
				bad: false,
				title: "No nasties",
				longTitle: "And absolutely no nasties",
				content:
					"We give you and " +
					name +
					" transparency from start to finish. No fillers, additives or any other nasties.",
			},
			{
				icon: "Fire",
				bad: true,
				title: "Highly processed",
				longTitle: "Kibble is highly processed",
				content:
					"Kibble is heated to 180°C and forced through a small tube (extruded). Even the best ingredients will have their nutritional benefits lost.",
			},
			{
				icon: "Skull",
				bad: true,
				title: "Poor quality ingredients",
				longTitle: "Often containing poor quality ingredients",
				content:
					"Kibble can include nasty additives, preservatives to extend shelf life. Some of these ingredients might not be optimal for " +
					name +
					"'s health and can lead to allergies or digestive issues.",
			},
			{
				icon: "Question",
				bad: true,
				title: "Designed to dupe",
				longTitle: "And misleading labelling tactics",
				content:
					"Kibble companies have been hiding behind confusing ingredient labels and skirting around the minimum requirements for years.",
			},
		],
	},
	raw: {
		title: {
			line1: "Free the freezer",
			line2: "Feed Air dried",
		},
		description:
			"We believe raw can be a good way to feed your dog, it’s healthy and isn’t highly processed. Much like us. We’ve just made it easier.",
		image1: "/images/pure-pet-food.jpg",
		image1AltText: "Pure Pet Food",
		image2: "/images/raw-dog-food.jpg",
		image2AltText: "Raw dog food",
		goodTitle: "The perks of Pure",
		badTitle: "The trouble with raw",
		sections: [
			{
				icon: "Star",
				bad: false,
				title: "Naturally dried",
				longTitle: "Pure is full of delicious, natural ingredients",
				content:
					"We gently dry high-quality ingredients to remove moisture and lock in the nutrients, without sacrificing quality. Just add water, stir and serve.",
			},
			{
				icon: "Heart",
				bad: false,
				title: "Invest in " + name + "’s health",
				longTitle: "Easily prepared in 30 seconds",
				content:
					"Our dogs are part of the family and deserve real food. They'll enjoy better bellies, shinier coats, increased vitality and much more. Healthier dogs live longer lives.",
			},
			{
				icon: "Award",
				bad: false,
				title: "No nasties",
				longTitle: "And contains absolutely no nasties",
				content:
					"We give you and " +
					name +
					" transparency from start to finish. No fillers, additives or any other nasties.",
			},
			{
				icon: "Time",
				bad: true,
				title: "Inconvenient",
				longTitle: "Raw food is inconvenient",
				content:
					"Defrosting raw food every single day can be a hassle. Forget to do it once and you’re in a real pickle. ",
			},
			{
				icon: "Freeze",
				bad: true,
				title: "Takes up freezer space",
				longTitle: "Requires both fridge and freezer space",
				content:
					"Raw food requires both fridge and freezer space, which can be a pain when kitchen space is at a premium.",
			},
			{
				icon: "Virus",
				bad: true,
				title: "Could carry nasties",
				longTitle: "And could contain harmful pathogens",
				content:
					"Raw food could contain harmful bacteria such as Salmonella, E. coli, Tapeworm, Listeria and Campylobacter.",
			},
		],
	},
	"wet food": {
		title: {
			line1: "Discover a",
			line2: "healthier dog food",
		},
		description:
			"Wet food is often a lot more processed than you think. By switching " +
			name +
			" from a processed wet food to Pure, they’ll be much healthier, live longer and visit the vet less.",
		image1: "/images/pure-pet-food.jpg",
		image1AltText: "Pure Pet Food",
		image2: "/images/wet-dog-food.jpg",
		image2AltText: "Wet dog food",
		goodTitle: "The perks of Pure",
		badTitle: "Worrisome wet food",
		sections: [
			{
				icon: "Star",
				bad: false,
				title: "Naturally dried",
				longTitle: "Pure is designed for better health",
				content:
					"We gently dry high-quality ingredients to remove moisture and lock in the nutrients, without sacrificing quality. Just add water, stir and serve.",
			},
			{
				icon: "Heart",
				bad: false,
				title: "Invest in " + name + "’s health",
				longTitle: "Full of natural, high quality ingredients",
				content:
					"Our dogs are part of the family and deserve real food. They'll enjoy better bellies, shinier coats, increased vitality and much more. Healthier dogs live longer lives.",
			},
			{
				icon: "Award",
				bad: false,
				title: "No nasties",
				longTitle: "And absolutely no nasties",
				content:
					"We give you and " +
					name +
					" transparency from start to finish. No fillers, additives or any other nasties.",
			},
			{
				icon: "Fire",
				bad: true,
				title: "Highly processed",
				longTitle: "Wet food is subject to harmful processing",
				content:
					"Wet food ingredients go through a harmful extrusion and emulsification process, meaning that even the best ingredients will lose their nutritional benefits.",
			},
			{
				icon: "Skull",
				bad: true,
				title: "Poor quality ingredients",
				longTitle: "Often made with sub-par ingredients",
				content:
					"Wet foods can contain gels, thickeners, coagulated or simulated meats, gums and even gelatinised starches - which can’t be the best for " +
					name +
					".",
			},
			{
				icon: "Question",
				bad: true,
				title: "Designed to dupe",
				longTitle: "And uses misleading labelling tactics",
				content:
					"Smelly, brown and slimy, wet dog food topped off with a mysterious gravy or jelly isn’t something we’d feed one of the family.",
			},
		],
	},
	"home prepared meals": {
		title: {
			line1: "Fresh food",
			line2: "without the faff",
		},
		description:
			"Creating " +
			name +
			"’s meals at home every day takes time, and might not be complete and balanced. Switch " +
			name +
			" to a more convenient, high-quality food and watch " +
			gender +
			"  thrive.",
		image1: "/images/pure-pet-food.jpg",
		image1AltText: "Pure Pet Food",
		image2: "/images/homemade-dog-food.jpg",
		image2AltText: "Homemade dog food",
		goodTitle: "The perks of Pure",
		badTitle: "Homemade or hard work?",
		sections: [
			{
				icon: "Star",
				bad: false,
				title: "Naturally dried",
				longTitle: "Pure is full of delicious, natural ingredients",
				content:
					"We gently dry high-quality ingredients to remove moisture and lock in the nutrients, without sacrificing quality. Just add water, stir and serve.",
			},
			{
				icon: "Heart",
				bad: false,
				title: "Invest in " + name + "’s health",
				longTitle: "Easily prepared in 30 seconds",
				content:
					"Our dogs are part of the family and deserve real food. They'll enjoy better bellies, shinier coats, increased vitality and much more. Healthier dogs live longer lives.",
			},
			{
				icon: "Award",
				bad: false,
				title: "No nasties",
				longTitle: "And contains absolutely no nasties",
				content:
					"We give you and " +
					name +
					" transparency from start to finish. No fillers, additives or any other nasties.",
			},
			{
				icon: "Time",
				bad: true,
				title: "Inconvenient",
				longTitle: "Home cooking is inconvenient",
				content:
					"Cooking a full meal every day is time-consuming, hard to get right and takes up valuable fridge freezer space.",
			},
			{
				icon: "Pie",
				bad: true,
				title: "Rarely complete and balanced",
				longTitle: "Often not complete and balanced",
				content:
					"Home cooking " +
					name +
					"’s meals means they likely aren’t getting the nutrition and nourishment they need, which could affect their overall health.",
			},
			{
				icon: "Pound",
				bad: true,
				title: "It's pricey",
				longTitle: "And is typically more expensive",
				content:
					"To feed " +
					name +
					" the same quality ingredients as in Pure you’re looking at a considerable price per meal.",
			},
		],
	},
	"pre-cooked food": {
		title: {
			line1: "Reclaim",
			line2: "Your kitchen",
		},
		description:
			"Pre-cooked food can often be inconvenient and more processed than you may think. Take back your freezer space and switch to a healthy, convenient food and watch as " +
			name +
			" thrives.",
		image1: "/images/pure-pet-food.jpg",
		image1AltText: "Pure Pet Food",
		image2: "/images/img-funnel-pre-cooked.jpg",
		goodTitle: "The perks of Pure",
		badTitle: "The problem with pre-cooked",
		sections: [
			{
				icon: "Star",
				bad: false,
				title: "Naturally dried",
				longTitle: "Pure is full of delicious, natural ingredients",
				content:
					"We gently dry high-quality ingredients to remove moisture and lock in the nutrients, without sacrificing quality. Just add water, stir and serve.",
			},
			{
				icon: "Heart",
				bad: false,
				title: "Invest in " + name + "’s health",
				longTitle: "Easily prepared in 30 seconds",
				content:
					"Our dogs are part of the family and deserve real food. They'll enjoy better bellies, shinier coats, increased vitality and much more. Healthier dogs live longer lives.",
			},
			{
				icon: "Award",
				bad: false,
				title: "No nasties",
				longTitle: "And contains absolutely no nasties",
				content:
					"We give you and " +
					name +
					" transparency from start to finish. No fillers, additives or any other nasties.",
			},
			{
				icon: "Time",
				bad: true,
				title: "Inconvenient",
				longTitle: "Pre-cooked is inconvenient",
				content:
					"Defrosting pre-cooked food every single day can be a hassle. Forget to do it once and you’re in a real pickle. ",
			},
			{
				icon: "Pie",
				bad: true,
				title: "Takes up freezer space",
				longTitle: "Requires both fridge and freezer space",
				content:
					"Pre-cooked food requires both fridge and freezer space, which can be a pain when kitchen space is at a premium.",
			},
			{
				icon: "Fire",
				bad: true,
				title: "Cooked at higher temps",
				longTitle: "And is often cooked at higher temperatures",
				content:
					"Pre-cooked foods are typically cooked at higher temperatures which can impact the nutritional value of food.",
			},
		],
	},
});
