import { gql } from "@apollo/client";

import {
	BENEFITS_BAR_FIELDS,
	FULL_WIDTH_FIELDS,
	IMAGE_TEXT_FIELDS,
	REVIEWS_FIELDS,
	CHOOSE_TABLE_FIELDS,
} from "./landingpageDato";

export const fetchFunnelPageDataBySlug = gql`
	${IMAGE_TEXT_FIELDS}
	${BENEFITS_BAR_FIELDS}
	${REVIEWS_FIELDS}
	${FULL_WIDTH_FIELDS}
	${CHOOSE_TABLE_FIELDS}
	query ($slug: String) {
		allFunnelPages(filter: { slug: { eq: $slug } }) {
			title
			slug
			sections {
				__typename
				...ImageTextFields
				...BenefitsBarFields
				...ReviewsFields
				...FullWidthFields
				...ChooseTableFields
			}
		}
	}
`;
