import {
	CustomerDelivery,
	CustomerLocal,
	CustomerPet,
	DeliveryAfterNextInformation,
	NextDeliveryInformation,
} from "@/types/AccountLocal";

import { getItemWeightDisplay } from "./helpers";

export const getNextDelivery = (deliveries: CustomerDelivery[]) => {
	return deliveries
		.sort((a, b) => a.delivery_date.getTime() - b.delivery_date.getTime())
		.find((delivery) => {
			return delivery.delivery_date >= new Date() || null;
		});
};

export const getDeliveryAfterNext = (deliveries: CustomerDelivery[]) => {
	return deliveries
		.sort((a, b) => a.delivery_date.getTime() - b.delivery_date.getTime())
		.filter((delivery) => delivery.delivery_date >= new Date())[1];
};

export const getNextDeliveryList = (
	user: CustomerLocal
): NextDeliveryInformation[] => {
	if (!user) {
		return [];
	}

	const listOfNextDeliveriesPerPet = user.pets?.map((pet) => {
		const nextDeliveryPet = pet.subscription.next_delivery;

		return {
			petId: pet.id,
			orderId: nextDeliveryPet?.id,
			billingDate: nextDeliveryPet?.billing_date,
			status: nextDeliveryPet?.status,
			discount: nextDeliveryPet?.discount_code,
			isLocked: nextDeliveryPet?.is_locked,
			oneOff: false,
			nextDelivery: nextDeliveryPet?.delivery_date
				? new Date(nextDeliveryPet?.delivery_date)
				: null,
		};
	});

	const nextDeliveriesInOrder = listOfNextDeliveriesPerPet
		?.filter(({ nextDelivery }) => nextDelivery !== null)
		.sort(
			// @ts-ignore
			(a, b) => a.nextDelivery - b.nextDelivery
		) as unknown as Required<NextDeliveryInformation>[];

	const nextDelivery = nextDeliveriesInOrder?.[0]?.nextDelivery;

	const nextDeliveryDuplicates = nextDeliveriesInOrder?.filter(
		(delivery) => delivery.nextDelivery.getTime() === nextDelivery.getTime()
	);

	return nextDeliveryDuplicates || null;
};

export const getDeliveryAfterNextDeliveryList = (
	user: CustomerLocal
): DeliveryAfterNextInformation[] => {
	if (!user) {
		return [];
	}

	const listOfNextDeliveriesPerPet = user.pets?.map((pet) => {
		const nextDeliveryPet = pet.subscription.delivery_after_next;

		return {
			petId: pet.id,
			orderId: nextDeliveryPet?.id,
			billingDate: nextDeliveryPet?.billing_date,
			isLocked: nextDeliveryPet?.is_locked,
			oneOff: false,
			deliveryAfterNext: nextDeliveryPet?.delivery_date
				? new Date(nextDeliveryPet?.delivery_date)
				: null,
		};
	});

	const nextDeliveriesInOrder = listOfNextDeliveriesPerPet
		?.filter(({ deliveryAfterNext }) => deliveryAfterNext !== null)
		.sort(
			// @ts-ignore
			(a, b) => a.deliveryAfterNext - b.deliveryAfterNext
		) as unknown as Required<DeliveryAfterNextInformation>[];

	const nextDelivery = nextDeliveriesInOrder?.[0]?.deliveryAfterNext;

	const nextDeliveryDuplicates = nextDeliveriesInOrder?.filter(
		(delivery) =>
			delivery.deliveryAfterNext.getTime() === nextDelivery.getTime()
	);

	return nextDeliveryDuplicates || null;
};

export const getNextDeliveryItems = (
	pet: CustomerPet,
	delivery: CustomerDelivery
) => {
	return {
		name: pet.name,
		gender: pet.gender,
		petId: pet.id,
		primary:
			delivery?.primary_items.map((item) => ({
				imageUrl: item.recipe.product.image,
				name: item.recipe.protein,
				subtitle: item.recipe.subtitle,
				weight: getItemWeightDisplay(item.variant_title, false),
			})) || [],
		secondary:
			delivery?.secondary_items.map((item) => ({
				imageUrl: item.recipe.product.image,
				name: item.title,
				quantity: item.quantity,
			})) || [],
	};
};
