import * as Sentry from "@sentry/node";
import { RewriteFrames, CaptureConsole } from "@sentry/integrations";

export const init = () => {
	if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
		const integrations = [];
		integrations.push(
			new CaptureConsole({
				levels: ["error"],
			})
		);
		if (process.env.NEXT_IS_SERVER === "true") {
			// For Node.js, rewrite Error.stack to use relative paths, so that source
			// maps starting with ~/_next map to files in Error.stack with path
			// app:///_next
			integrations.push(
				new RewriteFrames({
					iteratee: (frame) => {
						//@ts-expect-error erroring here
						frame.filename = frame.filename.replace("/out/", "app:///");
						frame.filename = frame.filename.replace(".next", "_next");

						return frame;
					},
				})
			);
		}

		Sentry.init({
			enabled: process.env.NODE_ENV === "production",
			integrations,
			dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
			environment:
				process.env.NEXT_PUBLIC_USABLE_ENV === "production"
					? "Frontend"
					: "Frontend Testing",
			ignoreErrors: [
				"Amplitude Logger [Error]",
				"[next-auth][error][CLIENT_FETCH_ERROR]",
				"NotAllowedError: The request is not allowed by the user agent",
				"Error: Loading initial props cancelled",
				"Error: Abort fetching component",
				"Amplitude Logger [Error]: Load failed",
				"Invariant: attempted to hard navigate to the same URL",
				"AbortError",
				"Failed to load",
				"Route did not complete loading",
			],
		});
	}
};
