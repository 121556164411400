import { gql } from "@apollo/client";

export const getAllLandingPages = gql`
	query allNewLandingPages($limit: IntType, $skip: IntType) {
		allNewLandingPages(
			first: $limit
			skip: $skip
			filter: { slug: { neq: "home" } }
		) {
			slug
		}
		_allNewLandingPagesMeta {
			count
		}
	}
`;

export const HERO_FIELDS = gql`
	fragment HeroFields on LandingHeroRecord {
		id
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		desktopImage {
			responsiveImage(
				imgixParams: { fit: crop, w: 1920, h: 800, auto: format }
			) {
				srcSet
				webpSrcSet
				sizes
				src
				width
				height
				aspectRatio
				alt
				title
				base64
			}
		}
		mobileImage {
			responsiveImage(
				imgixParams: { fit: fill, w: 600, h: 900, auto: format }
			) {
				srcSet
				webpSrcSet
				sizes
				src
				width
				height
				aspectRatio
				alt
				title
				base64
			}
		}
		imageTest
		testName
		imageTestOptions {
			desktopImage {
				responsiveImage(
					imgixParams: { fit: crop, w: 1920, h: 800, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			mobileImage {
				responsiveImage(
					imgixParams: { fit: fill, w: 600, h: 900, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
		}

		detailedVideoControls
		heroAsVideo
		desktopThumbnail {
			responsiveImage(
				imgixParams: { fit: fill, w: 1920, h: 1080, auto: format }
			) {
				srcSet
				webpSrcSet
				sizes
				src
				width
				height
				aspectRatio
				alt
				title
				base64
			}
		}
		mobileThumbnail {
			responsiveImage(
				imgixParams: { fit: fill, w: 600, h: 900, auto: format }
			) {
				srcSet
				webpSrcSet
				sizes
				src
				width
				height
				aspectRatio
				alt
				title
				base64
			}
		}
		autoplay
		desktopVideoUrl
		mobileVideoUrl
		darkImage
		lightTrustpilot
		trustpilot
		description
		offerBanner
		offerBannerContent {
			bannerHasCustomContent
			content
			textToUnderline
			backgroundColor
			link
			hasIllustrations
		}
		seasonalBanner
		button {
			link
			text
			buttonType
		}
		quickIntoFunnelWidget {
			widgetType
			hasDarkBackground
			title
			buttonText
			yesInfocardTitle
			yesInfocardContent
			yesInfocardIcon
			noInfocardTitle
			noInfocardContent
			noInfocardIcon
		}
	}
`;

export const TABLE_FIELDS = gql`
	fragment TableFields on LandingTableRecord {
		id
		tableHeading
		backgroundColour
		sideIcons
		bottomBorderZigZag
		tableRows {
			rowContent {
				isABlankCell
				isATickCell
				largeText
				tick
				image {
					url
				}
				content
				cellIsAHeading
			}
			rowIsAHeading
		}
	}
`;

export const PRICE_ESTIMATE_FIELDS = gql`
	fragment PriceEstimateFields on LandingPriceEstimateRecord {
		id
		textOnLeft
		heading
		caption
		backgroundImage {
			responsiveImage(
				imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
			) {
				srcSet
				webpSrcSet
				sizes
				src
				width
				height
				aspectRatio
				alt
				title
				base64
			}
		}
	}
`;

export const CHOOSE_TABLE_FIELDS = gql`
	fragment ChooseTableFields on LandingChooseTableRecord {
		id
		table {
			alignToSides
			ctaText
			ctaAction
			link
			tableLogo {
				responsiveImage(
					imgixParams: { fit: crop, w: 96, h: 96, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			table {
				id
				tableHeading
				tableHeading2
				backgroundColour
				sideIcons
				bottomBorderZigZag
				tableRows {
					rowContent {
						cellShowsUserDiscount
						isABlankCell
						isATickCell
						largeText
						tick
						alternateTick
						image {
							url
						}
						largeContent
						largeContentColour
						contentIsDeleted
						content
						contentColour
						cellIsAHeading
					}
					rowIsAHeading
				}
			}
		}
	}
`;

export const TIMELINE_FIELDS = gql`
	fragment TimelineFields on LandingTimelineRecord {
		id
		timelinePoints {
			heading {
				headingLine1
				headingLine2
				secondaryLineColour
				sizing
				split
			}
			image {
				responsiveImage(
					imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			imageBorder
			sideIcons
			content
			textOnLeft
			date
		}
	}
`;

export const IMAGE_TEXT_FIELDS = gql`
	fragment ImageTextFields on LandingImageTextRecord {
		id
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
			headingSize
			headingLink
		}
		backgroundImage {
			responsiveImage(
				imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
			) {
				srcSet
				webpSrcSet
				sizes
				src
				width
				height
				aspectRatio
				alt
				title
				base64
			}
		}
		videoUrl
		backgroundColour
		imageZigzag
		sideIcons
		content
		hideFromAccountShop
		textOnLeft
		iconPoints {
			icon
			text
		}
		stats {
			number
			description
		}
		accordions {
			title
			content
		}
		button {
			link
			text
			buttonType
		}
	}
`;

export const BENEFITS_BAR_FIELDS = gql`
	fragment BenefitsBarFields on LandingBenefitsBarRecord {
		id
		border
		verticalStack
		backgroundColour
		leftAlignedIcons
		squareCorners
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		iconPoints {
			icon
			text
			heading
			caption
		}
		button {
			link
			text
			buttonType
		}
	}
`;

export const QUOTES_FIELDS = gql`
	fragment QuotesFields on LandingQuoteRecord {
		id
		border
		heading {
			headingLine1
			headingLine2
			showTrustPilotReviews
			showTrustPilotCount
			secondaryLineColour
			sizing
			split
		}
		quotes {
			image {
				responsiveImage(
					imgixParams: { fit: crop, w: 400, h: 400, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}
			quote
			quoter
		}
		button {
			link
			text
			buttonType
		}
	}
`;

export const PRODUCTS_FIELDS = gql`
	fragment ProductsFields on LandingProductRecord {
		id
		border
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		content
		products {
			title
			slug
			handle
		}
	}
`;

export const EXTRAS_FIELDS = gql`
	fragment ExtrasFields on LandingExtraRecord {
		id
		border
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		content
		sideIcons
		products {
			title
			slug
			handle
		}
	}
`;

export const FULL_WIDTH_FIELDS = gql`
	fragment FullWidthFields on LandingFullWidthRecord {
		id
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		content
		backgroundColour
		sideIcons
		mealCounter
		iconPoints {
			icon
			text
		}
		stats {
			number
			description
		}
		button {
			link
			text
			buttonType
		}
	}
`;

export const STATISTIC_PANEL_FIELDS = gql`
	fragment StatisticPanelFields on StatisticBlockRecord {
		id
		mealTitle
		mealTitleLine2
		caption
	}
`;

export const SUCCESS_STORIES_FIELDS = gql`
	fragment SuccessStoriesFields on LandingSuccessStoriesContentRecord {
		id
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		successStoryCategories {
			categoryTitle
			successStories {
				petName
				ownerName
				description
				coverImage {
					image {
						responsiveImage(
							imgixParams: { fit: crop, w: 400, h: 400, auto: format }
						) {
							srcSet
							webpSrcSet
							sizes
							src
							width
							height
							aspectRatio
							alt
							title
							base64
						}
					}
				}
			}
		}
	}
`;

export const REVIEWS_FIELDS = gql`
	fragment ReviewsFields on LandingReviewRecord {
		id
		showHeading
		backgroundColour
		showReviewCount
		showReviews
	}
`;

export const DIET_COMPARISON_FIELDS = gql`
	fragment DietComparisonFields on LandingDietComparisonRecord {
		id
		heading {
			headingLine1
			headingLine2
			secondaryLineColour
			sizing
			split
		}
		description
		defaultDiet
	}
`;

export const SOCIAL_VIDEO_FIELDS = gql`
	fragment SocialVideoFields on LandingSocialVideoRecord {
		id
		title
		subtitle
		backgroundColour
		autoplay
		showVideoLength
		videos {
			id
			title
			videoUrl
		}
	}
`;

export const getLandingPageQuery = (preview: boolean) => {
	const stagingFilter = preview
		? `OR: [{hideFromLive: {eq: "true"}}, {hideFromLive: {eq: "false"}}]`
		: `hideFromLive: {eq: "false"}`;

	return gql`
	${HERO_FIELDS}
	${IMAGE_TEXT_FIELDS}
	${BENEFITS_BAR_FIELDS}
	${REVIEWS_FIELDS}
	${QUOTES_FIELDS}
	${SUCCESS_STORIES_FIELDS}
	${FULL_WIDTH_FIELDS}
	${TIMELINE_FIELDS}
	${PRODUCTS_FIELDS}
	${TABLE_FIELDS}
	${CHOOSE_TABLE_FIELDS}
	${PRICE_ESTIMATE_FIELDS}
	${EXTRAS_FIELDS}
	${DIET_COMPARISON_FIELDS}
	${SOCIAL_VIDEO_FIELDS}
	query ($slug: String) {
		allNewLandingPages(
			filter: { slug: { eq: $slug }, ${stagingFilter} }
		) {
			title
			metadata {
				title
				description
			}
			slug
			autoAppliedVoucher {
				voucher
			}
			showInSearch
			sections {
				__typename
				...HeroFields
				...ImageTextFields
				...BenefitsBarFields
				...ReviewsFields
				...QuotesFields
				...SuccessStoriesFields
				...DietComparisonFields
				...FullWidthFields
				...TimelineFields
				...TableFields
				...ChooseTableFields
				...ProductsFields
				...PriceEstimateFields
				...ExtrasFields
				...PriceEstimateFields
				...SocialVideoFields
			}
		}
	}
`;
};

export const STAT_QUERY = gql`
	{
		statistics {
			deliveries
			subscriptions
		}
	}
`;

export const RECIPE_STAT_QUERY = gql`
	query ($slug: String) {
		statistics(slug: $slug) {
			deliveries
			subscriptions
		}
	}
`;
