import { gql } from "@apollo/client";

export const REFERRAL_QUERY = gql`
	query GetReferralScheme($token: String) {
		referralScheme(token: $token) {
			token
			name
			pets
			scheme {
				name
				description
				amount
				discountCode {
					active
					code
					description
					amount
					type
					set_limit_amount
					type_limit
					start_date
					end_date
					only_apply_to_primary_items
					only_apply_to_secondary_items
					future_discounts {
						code
						description
						amount
						type
						set_limit_amount
						type_limit
						only_apply_to_primary_items
						only_apply_to_secondary_items
					}
				}
			}
		}
	}
`;

export const VOUCHER_QUERY = gql`
	query ($discount_code: String!) {
		discount(discount_code: $discount_code) {
			active
			code
			type
			amount
			description
			type_limit
			set_limit_amount
			start_date
			end_date
			only_apply_to_primary_items
			only_apply_to_secondary_items
			future_discounts {
				code
				description
				amount
				type
				set_limit_amount
				type_limit
				only_apply_to_primary_items
				only_apply_to_secondary_items
			}
		}
	}
`;
